import React, { useState, useEffect } from "react";
import Login from "./Login";
import SignUp from './SignUp';
import ForgotPassword from "./ForgotPassword";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import {FaArrowLeft} from "react-icons/fa";
import { motion } from 'framer-motion';

import { ReactComponent as ButtonEye } from '../buttonEye.svg';
import { ReactComponent as Logo } from '../logo.svg';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import './ContainerCenter.css'; // Import the CSS file

const styles = {

   container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    padding: "0.5vw",
    backgroundColor: "transparent",
   // paddingLeft:"1vw",
   // paddingRight:"10vw",
    paddingBottom:"20px",
    zIndex:9999,
  },

  header: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    width: "100%",
    height: "80px",
    paddingRight: "20px",
    paddingLeft: "20px",
   // backgroundColor:"red"
  },

  buttonContainer: {
    display: "flex",
    width: "220px",
    height: "60px",
    overflow: "hidden",
    borderRadius: "20px", // set border radius to half of button height
  
  },
  leftButton: {
    flex: 1,
    fontSize: "15px",
    fontFamily: 'Roboto Mono, serif',
    fontWeight: "400",
    padding: "none", // add left padding to move button to the left
    border: "none",
    outline: "none",
    backgroundColor: "#40434e",
    color: "#9da6b1",
    cursor: "pointer",
    display: "inline-block",
    textDecoration: "none",
    borderTopLeftRadius: "30px", // set half of the radius to top left corner
    borderBottomLeftRadius: "30px", // set half of the radius to bottom left corner
    transition: "background-color 0.2s ease-in-out ,fontWeight 0.2s ease-in-out, color 0.5s ease-in-out", // add transition effect
    
  },
  rightButton: {
    flex: 1,
    fontSize: "15px",
    fontFamily: 'Roboto Mono, serif',
    fontWeight: "400",
    padding: "none", // add right padding to move button to the right
    border: "none",
    outline: "none",
    backgroundColor: "#40434e",
    color: "#9da6b1",
    cursor: "pointer",
    display: "inline-block",
    textDecoration: "none",
    borderTopRightRadius: "30px", // set half of the radius to top right corner
    borderBottomRightRadius: "30px", // set half of the radius to bottom right corner
    transition: "background-color 0.2s ease-in-out ,fontWeight 0.2s ease-in-out, color 0.5s ease-in-out", // add transition effect

  },
  circleButton: {
    width: "60px",
    height: "60px",
    fontSize: "18px",
    borderRadius: "50%",
    border: "none",
    outline: "none",
    backgroundColor: "#40434e",
    color: "#9da6b1",
    cursor: "pointer",
    display: "inline-block",
    textDecoration: "none",
    transition: "background-color 0.2s ease-in-out ,fontWeight 0.2s ease-in-out, color 0.5s ease-in-out", // add transition effect

  },

  activeButton: {
    backgroundColor: "#008080",
    color: '#f8f8f8',
    fontSize: "16px",
    fontWeight: "bold",

  },

  formContainer: {

    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  //  backgroundColor: "yellow",
    padding:"10px",

  },
};

export default function NavBarLanding() {
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showCircle, setShowCircle] = useState(true);
  const [showForgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    navigate("/");
  }, []);

  const handleLoginClick = () => {
    if(showLogin) handleCircleClick();
    else {
    setShowLogin(true);
    setShowSignUp(false);
    setShowCircle(false);
    setForgotPassword(false);
  }
  };

  const handleSignupClick = () => {
    if(showSignUp) handleCircleClick();
    else {
    setShowLogin(false);
    setShowSignUp(true);
    setShowCircle(false);
    setForgotPassword(false);
  }
  };
  const handleCircleClick = () => {
    console.log("gere");
    setShowLogin(false);
    setShowSignUp(false);
    setShowCircle(true);
    setForgotPassword(false);
  }

  const handleForgotPasswordClick = () => {
    setForgotPassword(true);
    setShowLogin(false);
    setShowSignUp(false);
    setShowCircle(false);
    
  };

  const handleFormContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCircleClick();
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={styles.container}>
      {screenWidth > 840 && (
                  <div
                       style={{
                               position: 'absolute',
                               top: '20px',
                               left: '50%',
                               transform: 'translateX(-50%)',
                               zIndex: 1,
                              }}
                    >
                  <motion.div
                       style={{
                              fontSize: '25px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '700',
                             }}

                   whileHover={{ scale: 1.1 }}
                    >
      <Logo style={{ width: '50px', height: '50px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '50px', height: '50px', transform: 'scaleX(-1)' }}
      />
    </motion.div>
  </div>
)}

      <div style={styles.header}>
        <motion.button style={{...styles.circleButton,
          ...(showCircle && styles.activeButton),fontSize:"1.5vw"}}
                 onClick={handleCircleClick} whileHover={{ scale: 1.1 }} >
            { showCircle  ? <motion.div

animate={{ scale: [0, 1.3, 1] }}
transition={{ ease: "easeIn", duration: 0.7 }}
>
<ButtonEye style={{width: '25px', height: '25px'}}/>
</motion.div> :  <motion.div

animate={{ scale: [0, 1.3, 1] }}
transition={{ ease: "easeIn", duration: 0.7 }}
>
<RiEyeCloseFill style={{fontSize: "24px",}}/>
</motion.div>}
        </motion.button>

        <div style={styles.buttonContainer}>
          <motion.button style={{ ...styles.leftButton, 
                  ...((showForgotPassword || showLogin) && styles.activeButton) }}
                  onClick={handleLoginClick} whileHover={{ scale: 1.1 }}  >
                  <motion.div initial={{ scale: 0 }}
animate={{ scale: [0, 1.3, 1] }}
transition={{ ease: "easeIn", duration: 0.7 }} >
            { showForgotPassword 
                ? <FaArrowLeft style={{fontWeight: "900",}}/> 
                : "SIGN IN"
            } </motion.div>
          </motion.button>

          <motion.button style={{ ...styles.rightButton,
                  ...(showSignUp && styles.activeButton) }}
                  onClick={handleSignupClick} whileHover={{ scale: 1.1 }} 
                > <motion.div initial={{ scale: 0 }}
animate={{ scale: [0, 1.3, 1]}}
transition={{ ease: "easeIn", duration: 0.7 }} >SIGN UP</motion.div>
          </motion.button>
        </div>
      </div>
      { !showCircle &&
      <div className="formContainer" onClick={handleFormContainerClick}>
        {showLogin && <Login onButtonClick={handleForgotPasswordClick}/>}
        {showSignUp && <SignUp /> }
        {showForgotPassword && <ForgotPassword/ > }
      </div> }

      
    </div>
  );
}