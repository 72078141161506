import React, { useContext } from "react";
import { StateContext } from "../contexts";


import NavBarLanding from "./LandingPageNavigation/NavBarLanding";
const NavBarLoggedIn = React.lazy(() =>import("./LoggedNavigation/NavBarLoggedIn"));
//const NavBarLanding = React.lazy(() =>import("./LoggedNavigation/NavBarLanding"));

export default function NavBar() {
  const { state } = useContext(StateContext);
  if (state.user) {
    return <NavBarLoggedIn />
      
  } else {
    return < NavBarLanding />;
  }
}
