import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { RiEyeFill, RiEyeCloseFill } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import { useScore } from './ScoreContext';

const getRandomFrom = (arr) => arr[Math.floor(Math.random() * arr.length)];

//                 red      orange    yellow    green     blue      violet
const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']
const shapes = ['circle', 'triangle', 'square'];

const getShapeStyles = (diameter, color, shape) => {
  const styles = {
    circle: {
      shapeSt: {
        width: diameter,
        height: diameter,
        borderRadius: '50%',
        backgroundColor: color,
        borderRight: `0px solid #b2d8d8`,
        borderBottom: `0px solid ${color}`,
        borderLeft: `0px solid #b2d8d8`,
      },
      shapeEye: {
        position: "absolute",
        left: diameter / 4,
        top: diameter / 4,
        color: '#001E1E',
      },
    },
    triangle: {
      shapeSt: {
        backgroundColor: "transparent",
        width: diameter,
        height: diameter,
        borderRight: `${(diameter / 2)}px solid transparent`,
        borderBottom: `${diameter}px solid ${color}`,
        borderLeft: `${diameter / 2}px solid transparent`,
      },
      shapeEye: {
        position: "absolute",
        marginLeft: -diameter / 4,
        marginTop: diameter / 4,
        color: '#001E1E',
      },
    },
    square: {
      shapeSt: {
        width: diameter,
        height: diameter,
        backgroundColor: color,
        borderRadius: "5%",
        borderRight: `0px solid #b2d8d8`,
        borderBottom: `0px solid ${color}`,
        borderLeft: `0px solid #b2d8d8`,
      },
      shapeEye: {
        position: "absolute",
        left: diameter / 4,
        top: diameter / 5,
        color: '#001E1E',
      },
    },
  };

  return styles[shape];
};

const blinkAnimation = () => {return ["",""];};

const rotateAnimation = (duration) => {
  let rotationDirection= getRandomFrom([-360,360]);

  let animation = {
  animate: { rotate: rotationDirection },
  transition: { ease: "linear", duration: duration, repeat: Infinity }, 
        };
  return [animation,rotationDirection]

};

const recolorAnimation = (color, shape, diameter, duration) => {
  let morph_color = getRandomFrom(colors);

  for (; morph_color === color; morph_color = getRandomFrom(colors));

  let animation;
  if (shape === 'triangle') {
    animation= {
      animate: { borderBottom: `${diameter}px solid ${morph_color}` },
      transition: { ease: "linear", duration: duration, repeat: Infinity, repeatType: "reverse" },
    };
  } else {
    animation= {
      animate: { backgroundColor: morph_color },
      transition: { ease: "linear", duration: duration, repeat: Infinity, repeatType: "reverse" },
    };
  }

  return [animation,morph_color];
};

const resizeAnimation = (duration) => {
  let resize_factor = getRandomFrom([0.5, 0.67, 1.5, 2.0])

  let animation = {
  animate: { scale: resize_factor },
  transition: { ease: "linear", duration: duration, repeat: Infinity, repeatType: "reverse" },
};
  return [animation,resize_factor];
};

const reshapeAnimation = (random_animation, color, shape, diameter,duration) => {
  let animation;
  let reshape_shape = '';
  let first_shape_tri = 1;
  
  if (random_animation === "reshape_tri") {
    reshape_shape = "triangle";
    first_shape_tri = -1;
  } else {
    reshape_shape = getRandomFrom(shapes);
    while (reshape_shape === shape) {
      reshape_shape = getRandomFrom(shapes);
    }
  }

  if (reshape_shape === "triangle") {
    animation =  {
      animate: {
        borderRadius: "0%",
        borderRight: `${(diameter / 2) + 1}px solid #b2d8d8`,
        borderBottom: `${diameter}px solid ${color}`,
        borderLeft: `${diameter / 2}px solid #b2d8d8`,
      },
      transition: {
        repeat: Infinity,
        duration: duration,
        ease: "easeIn",
        repeatType: "reverse",
        delay: first_shape_tri * duration,
      },
    };
  } else if (reshape_shape === "circle") {
    animation=  {
      animate: {
        borderRadius: "50%",
      },
      transition: {
        repeat: Infinity,
        duration: duration,
        ease: "easeIn",
        repeatType: "reverse",
      },
    };
  } else {
    animation= {
      animate: {
        borderRadius: "5%",
      },
      transition: {
        repeat: Infinity,
        duration: duration,
        ease: "easeIn",
        repeatType: "reverse",
      },
    };
  }
  if(first_shape_tri===-1) return [animation,shape];
  return [animation,reshape_shape];
};

const getAnimation = (random_animation, color, shape, diameter, duration) => {
  switch (random_animation) {
    case "blink":
      return blinkAnimation();
    case "rotate":
      return rotateAnimation(duration);
    case "recolor":
      return recolorAnimation(color, shape, diameter,duration);
    case "resize":
      return resizeAnimation(duration);
    case "reshape":
    case "reshape_tri":
      return reshapeAnimation(random_animation,color, shape, diameter,duration);
    default:
      return {};
  }
};

const renderEyeAnimation = (myIllusion, shapeEye, duration) => {
  if (myIllusion === "blink") {
    return (
      <motion.div>
        <motion.div
          style={{ ...shapeEye }}
          animate={{
            opacity: [1, 0, 1],
            transition: {
              duration: duration,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "anticipate",
            },
          }}
        >
          <RiEyeCloseFill style={{ opacity: 1.0 }} />
        </motion.div>

        <motion.div
          style={{ ...shapeEye }}
          animate={{
            opacity: [0, 1, 0],
            transition: {
              duration: duration,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "anticipate",
            },
          }}
        >
          <RiEyeFill style={{ opacity: 1.0 }} />
        </motion.div>
      </motion.div>
    );
  } else {
    return (
      <motion.div>
        <motion.div style={{ ...shapeEye }}>
          <RiEyeFill style={{ opacity: 1.0 }} />
        </motion.div>
      </motion.div>
    );
  }
};


const CalibrationPoint = React.memo(({ point, handleButton,randomIllusion,index }) => {

    const { x, y, diameter, color, shape, choosen_point } = point;
    const { score,updateScore,testWrongAnswers,updateTest, resetTest } = useScore();

    const [pointColor, setPointColor] = useState(color);

    const shapeStyles = {
      width: diameter,
      height: diameter,
      position: 'absolute',
      top: y,
      left: x,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: diameter/2,
      color: "#001E1E",
      cursor: "pointer"
    };

    let myIllusion = '';
    let ilShape = shape;
    if(choosen_point) {
        myIllusion = randomIllusion;
        if(myIllusion === "reshape" && shape === "triangle") {
          ilShape = getRandomFrom(["circle","square"]);
          myIllusion = "reshape_tri";
        };    
      }

    const duration = getRandomFrom([1,1.5,2,2.5,3]);
    const animation = getAnimation(myIllusion, pointColor,shape,diameter,duration);

    const handleClick = () => {
      let wrong_answers = 0;
      if (!choosen_point) {
        setPointColor('gray');
        updateScore(-1);
        updateTest(1);
      } else {
        wrong_answers = testWrongAnswers;
        console.log(animation[1]);
        updateScore(3);
        resetTest();
        console.log("duration");
        console.log(duration);
        handleButton(point,wrong_answers,animation[1],duration,(score+3));     
      }
    };

    const { shapeSt, shapeEye } = getShapeStyles(diameter, pointColor, ilShape);

    

    let shapeElement = (
      <motion.div style={{ ...shapeSt }}
        key={uuidv4()}
        onClick={handleClick}

        {...(animation[0])}
        >
        {renderEyeAnimation(myIllusion, shapeEye,duration)}

      </motion.div>
      );
    
    return (
            <motion.div
                key={index}
                style={shapeStyles}
                animate={{ scale: [0.5, 0.53, 0.5] }}
                whileHover={{ scale: 0.55 }}
              >
              {shapeElement}
            </motion.div>
            );
  });

export default CalibrationPoint;