import React, { useEffect, useState } from 'react';

import MyCal from './MyCal/MyCal';
import TextContainer from './MyCal/TextContainer';
import GazeCalibrationTextContainer from './MyCal/GazeCalibrationTextContainer';
import { Helmet } from 'react-helmet';
import { ScoreProvider } from './MyCal/ScoreContext';
import TextScoreContainer from './MyCal/TextScoreContainer';
function AttentionVisual() {

  const[intro, setIntro] = useState(true);
  const[startCallibration, setStartCallibration] = useState(false);

  const introFunction = () => {
    setIntro(false);
  };
  const startFunction = () => {
    setStartCallibration(true);
  };
  const goBackIntro = () => {
    setIntro(true);
    setStartCallibration(false);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [showScore, setShowScore] = useState(false);
  const leaveApp = () =>  {
    setShowScore(true);
    //  setIntro(true);
     // setStartCallibration(false);
  };

  return (
    <>
    <ScoreProvider>
      <Helmet>
        <script src="https://webgazer.cs.brown.edu/webgazer.js?" async />
      </Helmet>
      <div style={{height: "100vh", backgroundColor:"#b2d8d8"}}>
        {intro && <TextContainer continueApp={introFunction}/> }
        {!startCallibration && !intro && <GazeCalibrationTextContainer contCalibration={startFunction} goBack={goBackIntro}/> }
        {startCallibration &&
        (screenWidth >= 900 ? (
          <MyCal leave={leaveApp}/>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            Your screen width is not large enough. Please use a larger device to access this feature.
          </div>
        ))}
        {showScore && <TextScoreContainer style={{zIndex:9999}}continueApp={introFunction}/> }
        
      </div>
      </ScoreProvider>
    </>
  );
}

export default AttentionVisual;
