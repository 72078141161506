import React,{useState,useEffect} from "react";
import { Form, Card, Alert} from "react-bootstrap";
import { useResource } from "react-request-hook";

import './ContainerCenter.css'; // Import the CSS file
import { motion } from 'framer-motion';

const styles = {

  formControlInput: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    color: "black",
    outline: "none",
    borderBottom: "0.1vw solid #445366",
    fontSize: "20px",
    fontFamily: 'Roboto, serif',
    fontWeight: "300",
    paddingBottom: "10px",
    marginTop: "10px",
    minHeight: "47px", 

  },
   button: {
    width: "160px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "#008080",
    color: '#f8f8f8',
    fontFamily: 'Roboto, serif',
    fontWeight: "700",
    outline:"none",
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '20px',
  },
};

export default function ForgotPassword() {
  const [email,getEmail] = useState("");
  const [forgotFailed, setForgotFailed] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const [message, forgotpassword] = useResource((email) => ({
    url: "/forgotpassword",
    method: "post",
    data: { email},
  }));


    useEffect(() => {
    if (message?.data?.success) {
      setForgotFailed(false);
      setForgotSuccess(true);
     // console.log(message.data.success);
    }

    if (message?.error) {
     // console.log(message?.error);
      setForgotFailed(true);
      setForgotSuccess(false);
      
    }
  }, [message]);

  const handleForgot = (e) => {

    if(email.includes('@')&&email.length!==0){
    e.preventDefault();
  //  console.log(email)
   
    forgotpassword(email);
  }
  };

  const getFontSize = (textLength) => {
  const defaultFontSize = 20;
  const minFontSize = 8;
  const charactersPerStep = 3;
  const characterThreshold = 20;

  if (textLength <= characterThreshold) {
    return defaultFontSize;
  }

  let decreaseFactor = Math.floor((textLength - characterThreshold) / charactersPerStep)+2;
  let calculatedFontSize = defaultFontSize - decreaseFactor;

  return calculatedFontSize > minFontSize ? calculatedFontSize : minFontSize;
};

const getInputStyle = (emailLength) => {
  return {
    ...styles.formControlInput,
    fontSize: `${getFontSize(emailLength)}px`,
  };
};

const handleKeyPress = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

  
  return (
    <motion.div className="position-absolute" whileHover={{ scale: 1.03 }}initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.3 }} // Set the duration of the animation>
          >
      <Card className="shadow-lg rounded" style={{width:"300px" ,backgroundColor: '#f8f8f8',}}>
        <Card.Body style={{paddingTop:"none"}}>
          <Form>   
            <motion.div whileHover={{ scale: 1.03 }}>
            <Form.Control
              style={getInputStyle(email.length)}
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => getEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              required
            /></motion.div>
          
            <motion.button
              style={styles.button}
             // variant="primary"
              type="submit"
              className="btn-block mt-4 border-0"
              onClick={handleForgot}
              whileHover={{ scale: 1.1 }}
            >
              SEND
            </motion.button>
          </Form>
         
          {(forgotSuccess || forgotFailed) && <Alert variant="success" style={{marginTop:'20px', fontSize:"14px"}}>Temporary password <br></br>sent to your email.</Alert>}
        </Card.Body>
      </Card>

    </motion.div>
  );
}
