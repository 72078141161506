// LoadingSpinner.js
import React from 'react';
import { motion } from 'framer-motion';

const LoadingSpinner = () => {
  const loadingDotVariants = {
    start: {
      y: '0%',
      transition: {
        duration: 0.5,
        yoyo: Infinity,
      },
    },
    end: {
      y: '100%',
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <motion.div
        variants={loadingDotVariants}
        initial="start"
        animate="end"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#333',
          borderRadius: '50%',
          margin: '0 3px',
        }}
        transition={{ duration: 0.5, yoyo: Infinity, delay: 0 }}
      />
      <motion.div
        variants={loadingDotVariants}
        initial="start"
        animate="end"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#333',
          borderRadius: '50%',
          margin: '0 3px',
        }}
        transition={{ duration: 0.5, yoyo: Infinity, delay: 0.2 }}
      />
      <motion.div
        variants={loadingDotVariants}
        initial="start"
        animate="end"
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#333',
          borderRadius: '50%',
          margin: '0 3px',
        }}
        transition={{ duration: 0.5, yoyo: Infinity, delay: 0.4 }}
      />
    </div>
  );
};

export default LoadingSpinner;
