import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from './logo.svg';
import { motion } from 'framer-motion';

const WebGazerComponent = ({ onReady }) => {
  const webgazer = window.webgazer;

  const [gazePoint, setGazePoint] = useState({ x: 0, y: 0 });

  useEffect(() => {
    webgazer
      .setRegression('ridge')
      .showPredictionPoints(false)
     // .setVideoViewerSize(85, 75)
      .begin();

     setTimeout(() => {
      webgazer.setVideoViewerSize(85, 75);
    }, 500);

    const checkWebGazerReady = () => {
      if (webgazer.isReady()) {
        onReady(true);
        const refreshGazePoint = () => {
  webgazer.getCurrentPrediction().then(prediction => {
    if (prediction) {
      setGazePoint({ x: prediction.x, y: prediction.y });
    } else {
      setGazePoint(null); // Set gazePoint to null if no valid prediction
    }
    requestAnimationFrame(refreshGazePoint);
  });
};
        refreshGazePoint();
      } else {
        setTimeout(checkWebGazerReady, 1000);
      }
    };

    checkWebGazerReady();

    

  }, []);

  return (
    <div style={{ position: 'relative' }}>
    {gazePoint && ( // Only render the logo when gazePoint is not null
      <motion.div
        initial={{ opacity: 0, x: gazePoint.x, y: gazePoint.y }}
        animate={{ opacity: 1, x: gazePoint.x, y: gazePoint.y }}
        transition={{ type: 'easeOut', duration: 0.1 }}
        style={{ position: 'absolute' }}
      >
        <Logo style={{width: '18px', height: '18px'}} />
      </motion.div>
      )}
    </div>
  );
};

export default WebGazerComponent;