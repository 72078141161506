import React, { useState, useEffect, useContext } from 'react';
import { VictoryLine, VictoryChart, VictoryAxis, 
        VictoryZoomContainer,VictoryScatter ,
        VictoryTooltip, VictoryBrushContainer,
        VictoryLabel,VictoryCursorContainer, VictoryVoronoiContainer} from 'victory';
import { Form, Card, Alert, Modal } from "react-bootstrap";

import { useResource } from "react-request-hook";
import { StateContext } from "../../../contexts";
import TestsData from './TestsData';

const mockData = [
  {
    sessionDate: "2022-01-01T12:00:00",
    sessionScore: 32,
    _id: "1"
  },
  {
    sessionDate: "2022-01-02T12:35:00",
    sessionScore: 36,
    _id: "2"
  },
  {
    sessionDate: "2022-01-03T12:00:00",
    sessionScore: 28,
    _id: "3"
  },
  {
    sessionDate: "2022-01-04T12:00:00",
    sessionScore: 180,
    _id: "4"
  },
  {
    sessionDate: "2022-01-05T12:00:00",
    sessionScore: 128,
    _id: "5"
  }
];

const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']
const SessionData = () => {
  const [data, setData] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionNo, setSelectedSessionNo] = useState(null);
  const [dataExists, setDataExists] = useState(false);

  const { state } = useContext(StateContext);
  const token = state.user;
  //console.log(token);

  const [sessions, getSessions] = useResource(() => ({
    url: "/getSessionData",
    method: "post",
    data: { userToken: token},
  }));

useEffect(() => {
  if (sessions?.data) {
    const formattedData = sessions.data.sessions.map((session) => ({
      x: new Date(session.sessionDate),
      y: session.sessionScore,
      _id: session.sessionId,
      sessionNo: session.sessionNo, // Add this line
    }));

    setData(formattedData);

    setDataExists(true);
  }
}, [sessions]);

useEffect(() => {
      getSessions();
  }, []);
  
/*

  React.useEffect(() => {
    // Format the data for Victory
    const formattedData = mockData.map((session) => ({
      x: new Date(session.sessionDate),
      y: session.sessionScore,
      _id: session._id
    }));

    setData(formattedData);
  }, []);  */

const [zoomDomain, setZoomDomain] = useState(null);
const [tickValues, setTickValues] = useState(0);
useEffect(() => {
    if (data && data.length > 0) {
      setZoomDomain({ x: [data[0].x, data[data.length - 1].x] });
    }
  }, [data]);

const handleClick = (datum) => {
  const sessionId = datum._id;
  const sessionNo = datum.sessionNo;
  
  setSelectedSession(sessionId);
  setSelectedSessionNo(sessionNo);
  handleShowModal();
};

  const handleZoom = (domain) => {
    setZoomDomain(domain);
  };

  const handleBrush = (domain) => {
    setZoomDomain(domain);
  };

const [showModal, setShowModal] = useState(false);

const handleShowModal = () => {
  setShowModal(true);
};

const handleCloseModal = () => {
  setShowModal(false);
  setSelectedSession(null);
  setSelectedSession(null);
};

  return (
    <div>
    
    {dataExists ? (
      <VictoryChart

        containerComponent={
          <VictoryZoomContainer zoomDomain={zoomDomain} onZoomDomainChange={handleZoom}
           minDomain={{ x: data[0]?.x, y: 0 }}
           maxDomain={{ x: data[data.length - 1]?.x, y: Math.max(...data.map((d) => d.y)) }}

          />
        }
        domainPadding={{ x: [20, 20], y: [20, 20] }}
      >
        <VictoryLine
          data={data}
          interpolation="linear"
          style={{
      data: { stroke: colors[5], strokeWidth: 0.75 }, // Change the color and width of the linear line
    }}
        />
      <VictoryScatter
  data={data}
  size={5}
  symbol="circle"
  
  labels={({ datum }) => `Session ${datum.sessionNo}\nScore: ${datum.y}\n${new Date(datum.x).toLocaleString()}`}
  
  labelComponent={
    <VictoryTooltip
      cornerRadius={2}
      flyoutStyle={{ fill: "#b2d8d8", stroke: "none" }}
      
      style={{ fontFamily: "Comfortaa ,serif", fontWeight: 400, fontSize: "6px",fill:'#196262' }}
    />
  }
  style={{
    data: {
      fill:"#f96c22",
      cursor: "pointer",
    },
  }}
  events={[
    {
      target: "data",
      eventHandlers: {
        onClick: (event, props) => {
          handleClick(props.datum);
        },
        onMouseOver: () => {
          return [
            {
              target: "data",
              mutation: () => ({ size: 7 }), // Change the size when hovering
            },
            {
              target: "labels",
              mutation: () => ({ active: true }),
            },
          ];
        },
        onMouseOut: () => {
          return [
            {
              target: "data",
              mutation: () => ({ size: 5 }), // Reset the size when not hovering
            },
            {
              target: "labels",
              mutation: () => ({ active: false }),
            },
          ];
        },
      },
    },
  ]}
/>
        <VictoryAxis
          label="Session Date"
          
          style={{
            grid: { stroke: "#237878", strokeWidth: 0.25 },
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 5, padding: 5, fontFamily: "Roboto,serif", fill: "#206d6d" }, // Change the font and color of the tick labels
            axisLabel: { fontSize: 12, padding: 20, fontFamily: "Roboto Mono,serif",fontWeight:400, fill: "#196262" }, // Change the font and color of the axis label
          }}
          tickFormat={(t) => new Date(t).toLocaleString()}

        />
        <VictoryAxis
          dependentAxis
          label="Session Score"
          style={{
            grid: { stroke: "#237878", strokeWidth: 0.25 },
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 5, padding: 5, fontFamily: "Roboto,serif", fill: "#206d6d" }, // Change the font and color of the tick labels
            axisLabel: { fontSize: 12, padding: 20, fontFamily: "Roboto Mono,serif",fontWeight:400, fill: "#196262" }, // Change the font and color of the axis label
          }}
        />

      </VictoryChart>) : (
      <p>No tests data available.</p>
)
    }
    <TestsData sessionId={selectedSession} sessionNo={selectedSessionNo} showModal={showModal} handleClose={handleCloseModal} />

    </div>
  );
};

export default SessionData;