import { createContext, useContext, useState } from 'react';

const ScoreContext = createContext();

export const useScore = () => {
  const context = useContext(ScoreContext);
  if (!context) {
    throw new Error('useScore must be used within a ScoreProvider');
  }
  return context;
};

export const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [testWrongAnswers, setTestWrongAnswers] = useState(0);

  const updateScore = (delta) => {
    setScore((prevScore) => prevScore + delta);
  };

  const updateTest = (delta) => {
    setTestWrongAnswers((prevScore) => prevScore + delta);
  };

  const resetTest = () => {
    setTestWrongAnswers(0);
  };

  return (
    <ScoreContext.Provider value={{ score, updateScore,testWrongAnswers,updateTest, resetTest }}>
      {children}
    </ScoreContext.Provider>
  );
};