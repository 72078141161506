import React from 'react';
import { motion } from 'framer-motion';

const TermsAndConditions = () => {
  return (
    <motion.div
      style={{
        fontFamily: 'Roboto Mono, serif',
        fontWeight: '300',
        fontSize: '13px',
        color: '#001E1E',
      }}
    >
  <span style={{fontWeight: '500',fontSize: '14px',}} > I. Terms and Conditions</span> <br></br>
 <br></br>
1. Introduction<br></br><br></br>
Welcome to Attention Visual, a web application developed for the purpose of conducting research on user attention and gaze tracking. This application is created as part of a master's thesis project and may be developed into a company in the future.<br></br>
<br></br>
By accessing and using Attention Visual, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you must not use the Attention Visual web application.<br></br>
<br></br>
Please read these Terms and Conditions carefully before using our application, and ensure that you also read and understand our Privacy Policy, which governs how we collect, use, and store your personal information while using Attention Visual.<br></br>
<br></br>
2. Copyright and Intellectual Property<br></br><br></br>
All content, features, and functionality within the Attention Visual web application, including but not limited to, text, graphics, logos, images, software, and any underlying technology, are the sole property of the creator and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.<br></br>
<br></br>
The compilation of all content within the Attention Visual web application is the exclusive property of the creator and is protected by copyright laws. Unauthorized use, copying, reproduction, modification, republishing, uploading, posting, transmitting, or distribution of any material or content within the Attention Visual web application is strictly prohibited and may result in legal action.<br></br>
<br></br>
By using the Attention Visual web application, you acknowledge and agree that you have no rights, title, or interest in or to the content, features, or functionality of the application, except as expressly granted under these Terms and Conditions.<br></br>
<br></br>
3. License to Use<br></br><br></br>
Subject to your compliance with these Terms and Conditions, the creator of Attention Visual grants you a limited, non-exclusive, non-transferable, non-sublicensable, and revocable license to access and use the Attention Visual web application solely for your personal, non-commercial purposes in connection with your participation in the research study.<br></br>
<br></br>
This license does not grant you any rights to use the Attention Visual name, logo, or any other proprietary content for any purpose without the prior written consent of the creator. You may not sell, rent, lease, sublicense, distribute, or otherwise transfer or exploit the Attention Visual web application or any part thereof, except as expressly permitted under these Terms and Conditions.<br></br>

You agree not to engage in any activities that could reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, underlying ideas, algorithms, or trade secrets of the Attention Visual web application. Any unauthorized use, copying, or distribution of the application or its content may result in the termination of your access to the application and may subject you to legal action.<br></br>
<br></br>
4. User Conduct<br></br><br></br>
By using the Attention Visual web application, you agree to abide by the following rules of conduct:<br></br>
<br></br>
a. You will not provide false or misleading information when creating an account, if applicable, or during the use of the application.<br></br>
<br></br>
b. You will not share your login credentials, if applicable, with any other person or allow any other person to access your account.<br></br>
<br></br>
c. You will not use the Attention Visual web application for any purpose that is illegal, fraudulent, or prohibited by these Terms and Conditions.<br></br>
<br></br>
d. You will not attempt to gain unauthorized access to any part of the Attention Visual web application, other users' accounts, or any computer systems or networks connected to the application.<br></br>
<br></br>
e. You will not use any automated means, including but not limited to, robots, spiders, or scripts, to access, monitor, or copy any part of the Attention Visual web application.<br></br>
<br></br>
f. You will not engage in any activity that may disrupt, interfere with, or adversely affect the functioning, integrity, or security of the Attention Visual web application or any related services, systems, or networks.<br></br>
<br></br>
g. You will not upload, post, or transmit any content that infringes upon the intellectual property rights or other rights of any third party or that is defamatory, obscene, abusive, harassing, or otherwise objectionable.<br></br>
<br></br>
h. You will not use the Attention Visual web application for any commercial purpose or in any manner that could harm, exploit, or otherwise adversely affect the creator, the application, or its users.<br></br>
<br></br>
Any violation of these rules of conduct may result in the termination of your access to the Attention Visual web application and may subject you to legal action.<br></br>
<br></br>
5. Limitation of Liability<br></br><br></br>
To the fullest extent permitted by law, in no event shall the creator of Attention Visual, or any of its affiliates, employees, agents, or representatives, be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or any other intangible losses, resulting from your access to or use of, or inability to access or use, the Attention Visual web application.<br></br>
<br></br>
This limitation of liability applies even if the creator of Attention Visual or its representatives have been advised of or should have been aware of the possibility of such damages. You agree that your sole and exclusive remedy for any dissatisfaction with the Attention Visual web application is to stop using the application and, if applicable, to delete your account.<br></br>
<br></br>
By using the Attention Visual web application, you agree to defend, indemnify, and hold harmless the creator, its affiliates, employees, agents, and representatives from and against any and all claims, damages, obligations, losses, liabilities, costs, or expenses, including reasonable attorneys' fees, arising out of or related to your use of the application, your violation of these Terms and Conditions, or your infringement or violation of any third party's rights.<br></br>
<br></br>
6. Termination<br></br>
The creator of Attention Visual reserves the right, in its sole discretion, to terminate or suspend your access to the web application, your account (if applicable), or any related services, without prior notice and at any time, for any reason, including but not limited to:<br></br>
<br></br>
a. Breach or violation of these Terms and Conditions or any other policies or guidelines incorporated herein.<br></br> <br></br>
b. Conduct that the creator believes is harmful to the Attention Visual web application, its users, or the interests of the creator.<br></br> <br></br>
c. Legal or regulatory requirements that may compel the creator to terminate or suspend access to the application.<br></br> <br></br>
<br></br>
Upon termination of your access to the Attention Visual web application, your right to use the application and any related services will immediately cease. All provisions of these Terms and Conditions that, by their nature, should survive termination, including but not limited to, intellectual property provisions, warranty disclaimers, indemnity, and limitations of liability, shall survive termination.<br></br>
<br></br>
The creator shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension of your access to the Attention Visual web application or any related services.<br></br>
<br></br>
7.Governing Law<br></br><br></br>
These Terms and Conditions, and any dispute or claim arising out of or in connection with them, shall be governed by and construed in accordance with the laws of the United States, State of Illinois, without regard to its conflict of law provisions.<br></br>
<br></br>
By using the Attention Visual web application, you agree to submit to the exclusive jurisdiction of the courts located in Cook County, Illinois, to resolve any legal matter arising from or related to these Terms and Conditions or your use of the Attention Visual web application. You and the creator of Attention Visual agree to waive any objections to the exercise of jurisdiction over the parties by such courts and to venue in such courts.<br></br>
<br></br>
If any provision of these Terms and Conditions is deemed invalid or unenforceable by a court of competent jurisdiction, such provision shall be severed, and the remaining provisions shall continue in full force and effect. The failure of the creator to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.<br></br>
<br></br><br></br>
<span style={{fontWeight: '500',fontSize: '14px',}} > II.Privacy Policy<br></br></span>
<br></br>
1.Introduction<br></br><br></br>
Your privacy is important to us. This Privacy Policy outlines how we collect, use, and store your personal information when you use the Attention Visual web application. By using the Attention Visual web application, you agree to the collection and use of your information in accordance with this Privacy Policy.<br></br>
<br></br>
We encourage you to read this Privacy Policy carefully, along with the Terms and Conditions, to understand our practices regarding your personal information and how we handle it. If you do not agree with any part of this Privacy Policy, you must not use the Attention Visual web application.<br></br>
<br></br>
Please note that this Privacy Policy may be updated from time to time, and we will notify you of any significant changes. Your continued use of the Attention Visual web application after we post any modifications to the Privacy Policy constitutes your acceptance of those modifications.<br></br>
<br></br>
2.Personal Information Collection<br></br><br></br>
When you use the Attention Visual web application, we may collect various types of personal information from you to provide and improve our services. The types of information we collect include, but are not limited to:<br></br>
<br></br>
a. Account Information: If you choose to create an account or participate in our research study, we may collect information such as your name, email address, and any other information you provide during the account creation process or while participating in the study.<br></br>
<br></br>
b. Usage Data: We collect information about your interactions with the Attention Visual web application, including the tests and sessions you complete, the time spent on each task, your responses, and other behavioral data.<br></br>
<br></br>
c. Gaze Tracking Data: We use webgazer.js to track your gaze while you are using the Attention Visual web application. This data includes the position of your eyes on the screen, the duration of your gaze on specific areas, and any other relevant gaze-tracking information.<br></br>
<br></br>
d. Technical Data: We may collect technical information about your device, browser, and operating system, such as your IP address, browser type and version, device type, screen resolution, and other similar data, to help us understand how users interact with the application and improve its performance.<br></br>
<br></br>
e. Cookies and Similar Technologies: We use cookies and other tracking technologies to collect information about your browsing activities, preferences, and to improve your overall experience with the Attention Visual web application. For more information on how we use cookies, please see the "Cookie Policy" section of this Privacy Policy.<br></br>
<br></br>
We collect this information either directly from you when you provide it to us or automatically as you use the Attention Visual web application.<br></br>
<br></br>
3.Use of Personal Information<br></br><br></br>
We collect and use your personal information for various purposes, including but not limited to:<br></br>
<br></br>
a. Providing and maintaining the Attention Visual web application: We use your information to deliver the services and features offered by the application, manage your account (if applicable), and ensure the proper functioning of the application.<br></br>
<br></br>
b. Research purposes: As Attention Visual is primarily a research tool, we use the collected data to conduct research, analyze user behavior, and improve our understanding of attention and gaze tracking. Your data may be used in aggregate form, ensuring that individual user identities are not disclosed.<br></br>
<br></br>
c. Improving the application: We use your information to analyze usage patterns, identify areas for improvement, and enhance the overall user experience. This may include developing new features, optimizing existing ones, or addressing technical issues that may arise.<br></br>
<br></br>
d. Communicating with you: We may use your contact information to send you important notices, updates, or other information related to the Attention Visual web application. We may also use your information to respond to your inquiries or provide support.<br></br>
<br></br>
e. Compliance with legal obligations: We may use your personal information to comply with applicable laws, regulations, or legal processes, or to protect the rights, property, or safety of Attention Visual, its users, or the public.<br></br>
<br></br>
f. Any other purpose for which you provide consent: In some cases, we may ask for your consent to use your personal information for specific purposes not listed above. If you provide your consent, we will use the information only for that purpose.<br></br><br></br>

We will not use your personal information for purposes unrelated to those described in this Privacy Policy without first obtaining your consent or providing you with an opportunity to opt out of such use.<br></br>
<br></br>
4.Data Retention<br></br>
We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, as outlined in this Privacy Policy, or to comply with legal, regulatory, or internal policy requirements.<br></br>
<br></br>
When your personal information is no longer required for the purposes described in this Privacy Policy, we will take appropriate measures to securely delete or anonymize your information, in accordance with applicable data protection laws and our internal data retention policies.<br></br>
<br></br>
In some cases, we may aggregate or anonymize your personal information in a manner that it can no longer be associated with you, and such aggregated or anonymized information may be retained and used for research, statistical analysis, or other purposes without any time limitation.<br></br>
<br></br>
Please note that the retention periods may vary depending on the type of information and the specific circumstances in which the information was collected. If you have any questions about our data retention practices, please contact us using the contact information provided in the "Contact Us" section of this Privacy Policy.<br></br>
<br></br>
5.Data Sharing<br></br><br></br>
We value your privacy and take appropriate measures to protect your personal information. We do not sell, rent, or otherwise disclose your personal information to third parties for their marketing or advertising purposes without your consent. However, we may share your information in the following circumstances:<br></br>
<br></br>
a. Research collaborators: As Attention Visual is a research-oriented application, we may share aggregated or anonymized data with research collaborators, academic institutions, or other partners for research purposes. We ensure that any shared data cannot be linked back to your identity.<br></br>
<br></br>
b. Service providers: We may engage third-party service providers to perform certain functions on our behalf, such as hosting, data storage, or analytics services. These providers will have access to your personal information only to the extent necessary to perform their functions and will be contractually obligated to protect your information in accordance with applicable data protection laws.<br></br>
<br></br>
c. Legal requirements: We may disclose your personal information if required to do so by law, in response to a court order or legal process, to enforce our Terms and Conditions, to protect our rights, property, or safety, or that of our users or the public, or in connection with a merger, acquisition, or sale of assets.<br></br>
<br></br>
d. Consent: We may share your personal information with third parties for any other purpose, provided that you have given your consent to such sharing.<br></br>
<br></br>
We take reasonable steps to ensure that any third party with whom we share your personal information will handle it in accordance with applicable data protection laws and in a manner consistent with this Privacy Policy.<br></br>
<br></br>
6.User Rights<br></br><br></br>
In accordance with applicable data protection laws, you have certain rights regarding your personal information that we collect and process. These rights may include, but are not limited to:<br></br>
<br></br>
a. Access: You have the right to request access to the personal information we hold about you and to receive a copy of that information.<br></br>
<br></br>
b. Rectification: If you believe that any personal information we hold about you is inaccurate or incomplete, you have the right to request that we correct or update it.<br></br>
<br></br>
c. Erasure: You have the right to request that we delete your personal information under certain circumstances, such as when it is no longer necessary for the purposes for which it was collected, or if you have withdrawn your consent (if applicable).<br></br>
<br></br>
d. Restriction of processing: In some cases, you may have the right to request that we restrict the processing of your personal information, such as when you contest its accuracy or object to its processing.<br></br>
<br></br>
e. Data portability: You have the right to request that we provide you with a copy of your personal information in a structured, commonly used, and machine-readable format, or to transmit it directly to another controller, where technically feasible.<br></br>
<br></br>
f. Objection: You have the right to object to our processing of your personal information for direct marketing purposes or when our processing is based on legitimate interests, and you believe that your rights and interests outweigh our legitimate reasons for processing.<br></br>
<br></br>
g. Withdrawal of consent: If we process your personal information based on your consent, you have the right to withdraw your consent at any time. Please note that the withdrawal of consent will not affect the lawfulness of processing based on consent before its withdrawal.<br></br>
<br></br>
To exercise any of these rights, please contact us using the contact information provided in the "Contact Us" section of this Privacy Policy. We may require you to verify your identity before we can respond to your request. We will respond to your request within a reasonable time frame and in accordance with applicable data protection laws.<br></br>
<br></br>
7.Cookie Policy<br></br><br></br>
Cookies are small text files that are placed on your device by websites that you visit. They are widely used to make websites work, improve user experience, or provide information to the website's owner. The Attention Visual web application uses cookies and other similar tracking technologies to enhance your experience while using the application, as well as to collect information about your browsing activities and preferences.<br></br>
<br></br>
a. Types of Cookies: We use both session cookies and persistent cookies on the Attention Visual web application. Session cookies are temporary and automatically deleted when you close your browser, while persistent cookies remain on your device until they expire or you manually delete them.<br></br>
<br></br>
b. Purpose of Cookies: The cookies used on the Attention Visual web application serve various purposes, such as:<br></br>
<br></br>
Authentication: We use cookies to recognize you when you return to the application, which helps to keep you logged in if you have not logged out.<br></br>
Performance and Analytics: We use cookies to collect usage data, analyze user behavior, and monitor the performance of the application, which helps us improve the application and its features.<br></br>
Preferences and Customization: We use cookies to remember your preferences, such as language, font size, or other settings, to provide a more personalized experience.<br></br>
c. Managing Cookies: Most web browsers allow you to manage cookies through the browser settings. You can choose to block all cookies, accept or reject cookies from specific websites, or delete cookies that have already been set. Please note that if you choose to block or delete cookies, some features and functionalities of the Attention Visual web application may not work properly, and your user experience may be affected.<br></br>
<br></br>
To learn more about how to manage cookies in your browser, please visit the relevant help page or consult your browser's documentation.<br></br>
<br></br>
By using the Attention Visual web application, you consent to the use of cookies in accordance with this Cookie Policy. If you do not agree to our use of cookies, you must change your browser settings to block or delete cookies, or stop using the Attention Visual web application.<br></br>
<br></br>
8.Changes to the Privacy Policy<br></br><br></br>
We reserve the right to update or modify this Privacy Policy at any time and from time to time to reflect changes in our data collection and processing practices, changes in applicable laws, or changes in our services or features. Any updates or modifications will be posted on this page, and the effective date of the updated Privacy Policy will be indicated at the top of the page.<br></br><br></br>
We will notify you of any significant changes to this Privacy Policy by sending an email to the email address associated with your account (if applicable), or by posting a notice on the Attention Visual web application. We encourage you to review this Privacy Policy periodically to stay informed about our data protection practices and your rights.<br></br>
<br></br>
Your continued use of the Attention Visual web application after we post any modifications to the Privacy Policy constitutes your acceptance of those modifications. If you do not agree to the updated Privacy Policy, you must stop using the Attention Visual web application.<br></br>
<br></br>
8.Contact Information<br></br><br></br>
If you have any questions or concerns regarding this Privacy Policy, your personal information, or your rights, please feel free to contact us at:<br></br>
<br></br>
Email: support@attentionvisual.com<br></br>
<br></br>
We will make every effort to promptly address your inquiries and resolve any concerns you may have about our data protection practices.<br></br>
<br></br>
Please note that the email address provided above is for privacy-related inquiries only. If you have any other questions or require support related to the Attention Visual web application, please refer to the support resources available within the application or the contact information provided in the Terms and Conditions.<br></br>
<br></br><br></br>

<span style={{fontWeight: '500',fontSize: '14px',}} > III. Accessibility Statement</span><br></br><br></br>
At Attention Visual, we are committed to making our web application accessible and inclusive for all users, including those with disabilities. We strive to ensure that our application meets the needs of a diverse audience by adhering to widely-recognized accessibility standards and guidelines, such as the Web Content Accessibility Guidelines (WCAG) 2.1.<br></br>
<br></br>
Our efforts to improve accessibility include:<br></br>
<br></br>
a. Designing and developing our web application with accessibility in mind, by incorporating features that enhance the user experience for individuals with varying abilities.<br></br>
b. Regularly reviewing and updating our web application to address any accessibility issues and ensure ongoing compliance with accessibility standards and best practices.<br></br>
c. Providing alternative means of accessing content and functionality for users with disabilities when it is not possible to make the web application fully accessible.<br></br>
d. Training our team members on accessibility issues and best practices to raise awareness and promote a culture of inclusivity.<br></br>
<br></br>
We recognize that accessibility is an ongoing process, and we are continuously working to improve our web application to better serve all users. If you have any questions, suggestions, or encounter any accessibility issues while using the Attention Visual web application, please contact us at support@attentionvisual.com. We appreciate your feedback and will make every effort to address your concerns and improve the accessibility of our application.<br></br>
<br></br><br></br>
<span style={{fontWeight: '500',fontSize: '14px',}} > IV. Age Restrictions</span><br></br><br></br>
Attention Visual does not have any age restrictions and is designed to be used by users of all ages. However, we encourage parents and guardians to monitor and guide the online activities of their children, particularly when providing personal information.<br></br>
<br></br>
Please note that we do not knowingly collect personal information from children under the age of 13, in compliance with the Children's Online Privacy Protection Act (COPPA). If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will take immediate steps to delete such information from our records.<br></br>
<br></br>
If you believe that we have collected personal information from a child under the age of 13, please contact us at support@attentionvisual.com, and we will take appropriate action to address the issue.<br></br><br></br>














    </motion.div>
  );
};

export default TermsAndConditions;