import React, { useEffect, useState } from 'react';

const Timer = ({ onTimerComplete }) => {
  const [remainingTime, setRemainingTime] = useState(5 * 60); // 3 minutes in seconds
  const [fontSize, setFontSize] = useState(20); // Initial font size
  const [paddingTop, setPaddingTop] = useState(25); // Initial padding top

  // Function to update font size and padding top based on window width
  const updateDimensions = () => {
    const windowWidth = window.innerWidth;
    // Calculate font size and padding top based on window width
    // Set a maximum font size of 20px
    const calculatedFontSize = Math.min(windowWidth / 50, 20);
    const calculatedPaddingTop = Math.min(windowWidth / 100, 25);
    setFontSize(calculatedFontSize);
    setPaddingTop(calculatedPaddingTop);
  };

  useEffect(() => {
    updateDimensions(); // Update dimensions on component mount
    window.addEventListener('resize', updateDimensions); // Update dimensions on window resize
    return () => {
      window.removeEventListener('resize', updateDimensions); // Cleanup event listener
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevRemainingTime) => {
        if (prevRemainingTime <= 1) {
          clearInterval(interval);
          return 0;
        } else {
          return prevRemainingTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      onTimerComplete(true);
    }
  }, [remainingTime, onTimerComplete]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const timerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 1,
    fontFamily: 'Roboto Mono, serif',
    color: '#001E1E',
    fontSize: `${fontSize}px`, // Use calculated font size
    paddingTop: `${paddingTop}px`, // Use calculated padding top
  };

  return (
    <div style={timerStyle}>
      <p style={{ fontWeight: 500, paddingRight: '10px', color: '#324a4a' }}>Time Left: </p>
      <p style={{ fontWeight: 700, color: minutes === 0 && seconds < 30 ? '#e30909' : '#001E1E', }}>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </p>
    </div>
  );
};

export default Timer;