function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
    case "REGISTER":
     // console.log(action);
      return action.token;
    case "LOGOUT":
     // console.log("hello");
      return "";
    default:
     // console.log(state);
      return state;
  }
}

function appStateReducer(state, action) {
  switch (action.type) {
    case "SET_APP_TRUE":
      return true;
    case "SET_APP_FALSE":
     // console.log("hi");
      return false;
    default:
      return state;
  }
}


export default function appReducer(state, action) {
  return {
    user: userReducer(state.user, action),
    appState: appStateReducer(state.appState, action),
  };
}