import React, { useState, useEffect } from 'react';
import { useScore } from './ScoreContext';

const Score = () => {
  const { score } = useScore();
  const [fontSize, setFontSize] = useState(20); // Initial font size
  const [paddingTop, setPaddingTop] = useState(25); // Initial padding top

  // Function to update font size and padding top based on window width
  const updateDimensions = () => {
    const windowWidth = window.innerWidth;
    // Calculate font size and padding top based on window width
    // Set a maximum font size of 20px
    const calculatedFontSize = Math.min(windowWidth / 50, 20);
    const calculatedPaddingTop = Math.min(windowWidth / 100, 25);
    setFontSize(calculatedFontSize);
    setPaddingTop(calculatedPaddingTop);
  };

  useEffect(() => {
    updateDimensions(); // Update dimensions on component mount
    window.addEventListener('resize', updateDimensions); // Update dimensions on window resize
    return () => {
      window.removeEventListener('resize', updateDimensions); // Cleanup event listener
    };
  }, []);

  const scoreStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
    fontFamily: 'Roboto Mono, serif',
    color: '#001E1E',
    fontSize: `${fontSize}px`, // Use calculated font size
    paddingTop: `${paddingTop}px`, // Use calculated padding top
  };

  return (
    <div style={scoreStyle}>
      <p style={{ fontWeight: 500, paddingRight: '10px', color: '#324a4a' }}>Score: </p>
      <p style={{ fontWeight: 700 }}>{score}</p>
    </div>
  );
};

export default Score;