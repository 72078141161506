import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryScatter, VictoryBar,VictoryLabel } from 'victory';
import { useResource } from "react-request-hook";
import { StateContext } from "../../../contexts";
import { motion } from 'framer-motion';

const TestsData = ({ sessionId, sessionNo, showModal, handleClose }) => {
  const [data, setData] = useState([]);
  //console.log(sessionNo);
  const { state } = useContext(StateContext);
  const sessionToken = state.user;

  const [tests, getTestsData] = useResource(() => ({
    url: "/getTestsData",
    method: "post",
    data: { sessionToken: sessionId, },
  }));

  useEffect(() => {
    if (tests?.data) {
      setData(tests.data.tests);
    }
  }, [tests]);

  useEffect(() => {
    if (sessionId) {
      getTestsData();
    }
  }, [sessionId]);

  return (
    <div style={{ borderRadius: '25px', overflow: 'hidden' }}>
  <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="lg"
        style={{
          maxHeight: '90%',
          overflowY: 'auto',
          marginTop: '50px',
          borderRadius: '25px',
        }}
      >
         <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      borderBottom: '1px solid #dee2e6',
      backgroundColor: '#196262',
      color: '#f8f8f8',
      fontFamily: 'Roboto Mono, serif',
      fontWeight: '500',
      fontSize: '50px',
      position: 'relative',
    }}
  >

    <div style={{ width: '1.5rem' }}></div>
    <h2 style={{ margin: 0, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      Session {sessionNo} Analysis
    </h2>
    <motion.button
      type="button"
      whileHover={{ scale: 1.2 }}
      onClick={handleClose}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '1.5rem',
        lineHeight: '1',
        color: '#f8f8f8',
        textShadow: '0 1px 0 #fff',
        opacity: '1.0',
        cursor: 'pointer',
      }}
    >
      &times;
    </motion.button>
  </div>
 
    
    <div  style={{backgroundColor:"#b2d8d8"}}>
      {data.length > 0 ? (
        <VictoryChart domainPadding={20}>
          <VictoryBar
            data={data}
            x="testNo"
            y="totalTime"
            labels={({ datum }) =>
              `Test ${datum.testNo}\n Test Level: ${10*(datum.n -3) + datum.testDifficulty} \n  Test Illusion: \n ${datum.testIllusion} \n    Total Time: ${datum.totalTime.toFixed(2)} s  \n Cognitive speed: \n ${datum.totalGazeOnPoint.toFixed(2)} s  `
            }
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{ fill: '#196262', stroke: "#b2d8d8" }}
                style={{ fontFamily: "Comfortaa ,serif", fontWeight: 200, fontSize: 10,fill: "#b2d8d8"}}
              />
            }

            style={{
                data: { fill: '#a660cf', cursor: "pointer" },
            }}

            events={[
  {
    target: 'data',
    eventHandlers: {
      onMouseOver: () => {
        return [
          {
            target: 'data',
            mutation: (props) => {
              return { style: { ...props.style, fill: '#196262' } };
            },
          },
          {
            target: 'labels',
            mutation: () => ({ active: true }),
          },
        ];
      },
      onMouseOut: () => {
        return [
          {
            target: 'data',
            mutation: (props) => {
              return { style: { ...props.style, fill: '#a660cf' } };
            },
          },
          {
            target: 'labels',
            mutation: () => ({ active: false }),
          },
        ];
      },
    },
  },
]}
          />
          <VictoryAxis
          label="Test Number"
          
          style={{
            grid: { stroke: "#237878", strokeWidth: 0.25 },
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 10, padding: 5, fontFamily: "Roboto,serif", fill: "#206d6d" }, // Change the font and color of the tick labels
            axisLabel: { fontSize: 15, padding: 20, fontFamily: "Roboto Mono,serif",fontWeight:500, fill: "#f96c22" }, // Change the font and color of the axis label
          }}
          

        />
          <VictoryAxis
          dependentAxis
          label="Total Time"
          style={{
            grid: { stroke: "#237878", strokeWidth: 0.25 },
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fontSize: 10, padding: 5, fontFamily: "Roboto,serif", fill: "#206d6d" }, // Change the font and color of the tick labels
            axisLabel: { fontSize: 15, padding: 20, fontFamily: "Roboto Mono,serif",fontWeight:500, fill: "#f96c22" }, // Change the font and color of the axis label
          }}
        />

        <VictoryLine
  y={() => data.reduce((sum, test) => sum + test.totalTime, 0) / data.length}
  style={{ data: { stroke: '#FD0B0B', strokeWidth: 1.5 } }}
/>
<VictoryLabel
  text={`Your Average Response Time is ${(data.reduce((sum, test) => sum + test.totalTime, 0) / data.length).toFixed(2)} seconds`}
  x={50}

  y={data.reduce((sum, test) => sum + test.totalTime, 0) / data.length}
  textAnchor="start"
  style={{ fontSize: 9, fill: '#FD0B0B', fontFamily: 'Roboto, serif', fontWeight:400 }}
/>
        </VictoryChart>
      ) : (
        <p>No tests data available.</p>
      )}
    </div>
    
  </Modal>
  </div>
);
}

export default TestsData;