import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from './logo.svg';
import './TextContainer.css'; // Import the CSS file

const styles = {
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "transparent",
  },
  textContainer: {
    backgroundColor: '#f8f8f8',
    color: '#001E1E',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '85vh',
    overflowY: 'auto',
    margin: '5vh 0',
    fontSize: '1.2rem',
    fontFamily: 'Roboto, serif',
    fontWeight: 400,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
   // boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.15)', // Add a subtle shadow

  },
 
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '20px',
  },

  startssButton: {
    backgroundColor: '#008080',
    color: '#F8F8F8',
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto Mono, serif',
    fontWeight: 500,
    width: 'fit-content',
  },
  startButton: {
    // ... other styles
    backgroundColor: '#008080',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
  },
  goBackButton: {
    // ... other styles
     // ... other styles
    backgroundColor: '#FD0B0B',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow

  },
  logoStyle: {
    width: '100px',
    height: '100px',
    marginBottom: '10px',
  },
  numberStyle: {
    color: '#e30909',
    fontWeight: 500,
  },
};


const InstructionsContainer = ({ onStartSession }) => {
  const handleStartSession = () => {
    onStartSession();
  };

const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']
  return (
    <div style={styles.formContainer}>
      <motion.div
        style={styles.textContainer}
        className="text-container"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.03 }}
      >
        <motion.div initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ flexShrink: 0 }}>
          <Logo style={styles.logoStyle} />
        </motion.div>

        <motion.h2 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: '#FD0B0B', fontFamily: 'Roboto Mono, serif', fontWeight: 500 }}>🎉 Welcome to the Visual Attention Training Session! 🎉</motion.h2>

        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >You've successfully completed the calibration process, and now you're ready for the real challenge! In this session, you'll be training your visual attention by identifying animated points among a sea of distractions. Don't worry, just stay relaxed and follow the simple instructions below.</motion.p>

        <motion.h3 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: colors[5], fontFamily: 'Roboto Mono, serif', fontWeight: 400 }}>🚀 Step 1: Start the Session</motion.h3>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >Press the "Start Session" button to begin your 5-minute visual attention training session. Get ready, the fun is about to begin!</motion.p>

        <motion.h3 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: colors[4], fontFamily: 'Roboto Mono, serif', fontWeight: 400 }}>🤖 Step 2: AI-Powered Challenge</motion.h3>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >During the session, you'll see a bunch of points on the screen. As you progress, our advanced machine learning algorithm will adapt the challenge to your performance. You'll face more shapes, colors, and increased density of points, all tailored to optimize your training experience. Stay focused and watch out for the animated point!</motion.p>

        <motion.h3 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: colors[3], fontFamily: 'Roboto Mono, serif', fontWeight: 400 }}>🧠 Step 3: Master the Illusions</motion.h3>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >The animated point will perform one of the following animations, known as illusions: resize, recolor, reshape, rotate, or blink. Your goal is to click on the animated point as quickly and accurately as possible.</motion.p>

        <motion.h3 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: colors[1], fontFamily: 'Roboto Mono, serif', fontWeight: 400 }}>✅ Step 4: Track Your Progress</motion.h3>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >For each correct click, you'll earn 3 points. However, if you click on the wrong point, you'll lose 1 point. Keep practicing every day to improve your visual attention and increase your score!</motion.p>

        <motion.h3 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ color: colors[0], fontFamily: 'Roboto Mono, serif', fontWeight: 400 }}>🧘 Step 5: Stay Relaxed</motion.h3>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >Remember, the key to success is staying relaxed and focused. Enjoy the session, and don't forget to breathe!</motion.p>

        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >Ready to challenge your visual attention with our cutting-edge AI-powered training? Press "Start Session" and let the magic begin! 🌟</motion.p>

        <div style={styles.buttonsContainer}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.startButton}
            onClick={handleStartSession}
          >
            Start Session
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default InstructionsContainer;