import React, { useState, useEffect,useContext} from 'react';
import { v4 as uuidv4 } from 'uuid';

import LoadingComponent from './LoadingComponent';
import GazeTracker from './GazeTracker';
import CalibrationPoint from './CalibrationPoint';

//import { ScoreProvider } from './ScoreContext';
import { StateContext } from "../../../contexts";
import Timer from './Timer';
import Score from './Score';

import WebGazerComponent from './WebGazerComponent';

import Callibrate from './Callibrate';

import { motion } from 'framer-motion';

import InstructionsContainer from './InstructionsContainer';

import { useResource } from "react-request-hook";

const getRandomFrom = (arr) => arr[Math.floor(Math.random() * arr.length)];
const nElementArray = (array, n) => [...array].sort(() => 0.5 - Math.random()).slice(0, n);

//                 red      orange    yellow    green     blue      violet
const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']
const illusions = ['resize' , 'recolor', "reshape", "rotate", "blink"];
const shapes = ['circle', 'triangle', 'square'];

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: 'transparent',
    paddingRight: "50px",
    paddingBottom: "20px",
    zIndex: 9999,
  },

  header: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    padding: "10px",
    width: "100%",
    height: "100px",
    backgroundColor: 'transparent',
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%", // Adjust this value to control the spacing between Score and Timer
    backgroundColor: 'transparent',
    paddingRight: "50px",
  },

  formContainer: {
    display: "flex",
    flex: 1,
    backgroundColor: "transparent",
  },

  endSessionButton: {
    position: "absolute",
    right: "10px",
    top: "25%", 
    backgroundColor: "#008080",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    cursor: "pointer",
    fontFamily: 'Roboto, serif', 
    fontWeight: 500,
  },

};

const MyCal = ({leave}) => {
  
  const [callibrated, setCallibrated] = useState(false); // check if webgazer is callibrated

  const [isWebgazerReady, setIsWebgazerReady] = useState(false); // checks if webgazer is loaded and ready
  const handleWebGazerReady = (ready) => { setIsWebgazerReady(ready); }; // 


  const [n, setN] = useState(4);
  const [difficulty, setDifficulty] = useState(1);

  const [d,dd] = useState(false);
  const [points, setPoints] = useState([]);
  const [randomIllusion, setIllusions] = useState("");
  const [re, setRe] = useState(false);
  const padding = 20; // Padding between points


  const [sessionID, setSessionID] = useState("");
  const [testID, setTestID] = useState("");
 
  // Set up the calibration points
  useEffect(() => {
    const { offsetWidth: containerWidth, offsetHeight: containerHeight } 
                          = document.getElementById('callibration-container');

    const diameter = Math.min(
      (containerWidth - padding * (n + 1)) / n,
      (containerHeight - padding * (n + 1)) / n,
      (containerHeight - padding * 9) / 8
    );

    const points = [];
    let choosen_i = Math.floor(Math.random() * n); 
    let choosen_j = Math.floor(Math.random() * n); 
    let illusionPoint = getRandomFrom(illusions);
    setIllusions(illusionPoint);

    let randomColors, randomShapes; // colors: 1 min 6 max, shapes: 1 min 3 max
    if (difficulty <= 3) {
        randomColors = nElementArray(colors,2); 
        randomShapes = nElementArray(shapes,1);
      } else if (difficulty <= 8) {
        randomColors = nElementArray(colors,4); 
        randomShapes = nElementArray(shapes,2);
      } else {
        randomColors = colors;
        randomShapes = shapes;
      }

    let pointNumber = 0;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        pointNumber++;
      //  let choosen_point = false;
        const x = padding + 0.95 * i * containerWidth / (n - 1);
        const y = 4 * padding + 0.90 * j * containerHeight / (n - 1);
        const color = getRandomFrom(randomColors);
        const shape = getRandomFrom(randomShapes);

        const choosen_point = i === choosen_i && j === choosen_j;
        if(!callibrated) {points.push({ x, y, diameter, color, shape, choosen_point,callibrated:false });}
        else {
          points.push({ x, y, diameter, color, shape, choosen_point }); 
          if(choosen_point) {

            startTest(n,difficulty,{ x: x, y: y },color,illusionPoint,pointNumber);
            /* 
                createTest with attributes:

                session_id: sessionID,
                n: n,
                testDifficulty: testDifficulty,
                choosenPointLocation:{ x: x, y: y },
                choosenPointColor: color,
                testIllusion: illusionPoint,
                pointNumber: pointNumber,
                
               // success
                setTestID(returnedToken);
            */

          }

        }
      }
    }
    setPoints(points);
    console.log("rerender")
  }, [re]);

// handle screen resizing.
  useEffect(() => {
    const updatePoints = () => {      
      const { offsetWidth: containerWidth, offsetHeight: containerHeight } 
                          = document.getElementById('callibration-container');
      const diameter = Math.min(
        (containerWidth - padding * (n + 1)) / n,
        (containerHeight - padding * (n + 1)) / n,
        (containerHeight - padding * 9) / 8
      );
     
      const updatedPoints = points.map((point,index) => {
      const x = padding + 0.95 * Math.floor(index / n) * containerWidth / (n - 1);
      const y = 4 * padding + 0.90 * (index % n)* containerHeight / (n - 1);
      return { ...point, x, y, diameter };
      
      });
      setPoints(updatedPoints);
    };

     window.addEventListener('resize', updatePoints);

     return () => window.removeEventListener('resize', updatePoints);
  }, [points]);

  const [getData, setGetData] = useState(false);
  const handleCorrectButtonClick = (point, wrongClicks,animatesTo,duration,score) => {

    /* 
      //


      // updateAnimationPoint

      animatesTo: animatesTo,
      WrongClicks: wrongClicks,
      duration: duration,


    */

    if(callibrated && !getData) {setGetData(true);}
    const { choosen_point } = point;
    console.log("Wrong Answers: ")
    console.log(wrongClicks);
    if (choosen_point) {
      console.log(sessionID);
      console.log(score);
       updateSessionScore(score);
       updateTestAnimation(animatesTo, wrongClicks, duration);
    dd(!d);
    } 
};


  // check if all points are callibrated for gaze Tracking
  const handleCallibrationComplete = (point) => {
      point.callibrated =true;
      if (points.every(point => point.callibrated === true)) {
       //   setCallibrated(true);
          setIntro(false);
          setExitButtonMessage("Quit");
       //   setN(4);
       //   dd(!d);
          }
  }

  // render Callibration Points, 
  const renderCalibrationPoint = (point, index) => {
    if(callibrated) {
      return ( <CalibrationPoint
                  key={uuidv4()}
                  point={point}
                  handleButton={handleCorrectButtonClick}
                  randomIllusion={randomIllusion}
                  index={index} /> );
      }  
    return ( <Callibrate
                key={uuidv4()}
                point={point}
                handleButton={handleCallibrationComplete}
                index={index} /> );
  };


  const handleUserResponse = (responseTime) => {
  let threshold = Math.min(n * 2, 10);
  // Process the user's response and update the score as needed
  if (responseTime < threshold) {
    if (difficulty < 10) {
      setDifficulty((prevDifficulty) => prevDifficulty + 1);
    } else {
      // Increase n by 1 and reset difficulty to 1
      if (n < 10) {
        setN((prevN) => prevN + 1);
        setDifficulty(1);
      }
    }
  } else {
    if (difficulty > 1) {
      setDifficulty((prevDifficulty) => prevDifficulty - 1);
    } else {
      if (n > 3) {
        setN((prevN) => prevN - 1);
        setDifficulty(10);
      }
    }
  }
  setRe(!re);
};

  const handlePointClicked = (totalTime, timeTillGaze, timeAfterGaze, avarageFps, initialGazeLocation, distanceToChoosenPoint, totalGazeOnPoint) => {

    if(getData){
      updateTestGaze(totalTime, timeTillGaze, timeAfterGaze, avarageFps, initialGazeLocation, distanceToChoosenPoint, totalGazeOnPoint)
    
    /* 
      // send GazeTracker Data to database: 
      updateTest

      totalTime: 0, 
      timeTillGaze: 0, 
      timeAfterGaze: 0, 
      avarageFps: 0, 
      initialGazeLocation: { x: 0, y: 0 }, 
      distanceToChoosenPoint: 0,
      totalGazeOnPoint: 0,
    */ 
    //  console.log(' AAA:', (timeAfterGaze));
    }

    handleUserResponse(timeAfterGaze);
    

    

    
  };


  const [isGazeTrackerLoaded, setIsGazeTrackerLoaded] = useState(false);

  const checkGazeTrackerLoaded = () => {
    setTimeout(() => {
          setIsGazeTrackerLoaded(true);
    }, 4000);
  };


  // Handle Countdown- Timer Complete 
  const [timerComplete, setTimerComplete] = useState(false);
const stopCamera = () => {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      stream.getTracks().forEach(track => track.stop());
      console.log("ok");
    })
    .catch(err => {
      console.error('Error stopping the camera:', err);
    });
}


  const handleTimerComplete = (completed) => {
    if(!callibrated) window.location.reload();
    setTimerComplete(completed);
    window.webgazer.showVideo(false);
    window.webgazer.pause(); // Pause the tracker
    stopCamera();
    // Manually stop the media tracks associated with the video element
    const videoPreview = document.getElementById('webgazerVideoFeed');
    if (videoPreview && videoPreview.srcObject) {
      const tracks = videoPreview.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoPreview.srcObject = null;
    }

    // Remove video preview element
    if (videoPreview) {
      videoPreview.remove();
    }

    // Hide the prediction point (gaze dot)
    const gazeDot = document.getElementById('webgazerGazeDot');
    if (gazeDot) {
      gazeDot.style.display = 'none';
    }

    window.webgazer.end(); // end the tracker
    
    if(callibrated) leave();

   // window.location.reload();
  };

  const { state, dispatch } = useContext(StateContext);
  const { user,appState } = state;
  const token = state.user;

  const [session, startSession] = useResource((token,sessionPixelSize) => ({
    url: "/startSession",
    method: "post",
    data: { token,sessionPixelSize},
  }));


    useEffect(() => {
    if (session?.data) {
     // console.log('Session object:', session); // Log the entire session object
     // console.log('Session object2:', session.sessionToken); // Log the entire session object

     // console.log(session.data.sessionToken);
      setSessionID(session.data.sessionToken);
    }

    if (session?.error) {
      console.log(session?.error);    
      
    }
  }, [session]);


  const [test, startTest] = useResource((n, testDifficulty,choosenPointLocation,choosenPointColor,testIllusion, pointNumber) => ({
    url: "/createTest",
    method: "post",
    data: { sessionToken: sessionID,n, testDifficulty,choosenPointLocation,choosenPointColor,testIllusion, pointNumber},
  }));


    useEffect(() => {
    if (test?.data?.testToken) {
     // console.log('Session object:', session); // Log the entire session object
     // console.log('Session object2:', session.sessionToken); // Log the entire session object

     // console.log(session.data.sessionToken);
      setTestID(test.data.testToken);
    }

    if (test?.error) {
      console.log(test?.error);    
      
    }
  }, [test]);


  // Hook for updating the session score
  const [updateResponse, updateSessionScore] = useResource((sessionScore) => ({
    url: '/updateSessionScore',
    method: 'post',
    data: { sessionToken: sessionID, sessionScore }, // Use sessionID as sessionToken
  }));

    // Handle the response from the updateSessionScore endpoint
  useEffect(() => {
    if (updateResponse?.data?.message) {
      console.log(updateResponse.data.message);
    }

    if (updateResponse?.error) {
      console.log(updateResponse?.error);
    }
  }, [updateResponse]);

  // Hook for updating the session score
  const [updateTestResponse, updateTestGaze] = useResource((totalTime, timeTillGaze, timeAfterGaze, avarageFps, initialGazeLocation, distanceToChoosenPoint, totalGazeOnPoint ) => ({
    url: '/updateTestGaze',
    method: 'post',
    data: { testToken: testID, totalTime, timeTillGaze, timeAfterGaze, avarageFps, initialGazeLocation, distanceToChoosenPoint, totalGazeOnPoint }, // Use sessionID as sessionToken
  }));

    // Handle the response from the updateSessionScore endpoint
  useEffect(() => {
    if (updateTestResponse?.data?.message) {
      console.log(updateResponse.data.message);
    }

    if (updateTestResponse?.error) {
      console.log(updateTestResponse?.error);
    }
  }, [updateTestResponse]);


    // Hook for updating the session score
  const [updateTestAnimationResponse, updateTestAnimation] = useResource((animatesTo, wrongAnswers, duration ) => ({
    url: '/updateTestAnimation',
    method: 'post',
    data: { testToken: testID, animatesTo, wrongAnswers, duration }, // Use sessionID as sessionToken
  }));

    // Handle the response from the updateSessionScore endpoint
  useEffect(() => {
    if (updateTestAnimationResponse?.data?.message) {
      console.log(updateTestAnimationResponse.data.message);
    }

    if (updateTestAnimationResponse?.error) {
      console.log(updateTestAnimationResponse?.error);
    }
  }, [updateTestAnimationResponse]);

  const[intro, setIntro] = useState(true);

  // get the estimate pixels per inch for accurate comparison in analyzses.
function getPixelsPerInch() {
  const inch = 96;
  const referenceElement = document.createElement("div");
  referenceElement.style.width = inch + "px";
  referenceElement.style.height = inch + "px";
  referenceElement.style.display = "block"; // Temporarily set display to "block"
  document.body.appendChild(referenceElement);
  const pixelSize = referenceElement.offsetHeight;
  document.body.removeChild(referenceElement); // Remove the element from the DOM
  const pixelsPerInch = (pixelSize / inch) * 96; // Calculate the ratio and multiply by reference value (96)
  return pixelsPerInch;
}
const handleStartSessionClicked = () => {
  setExitButtonMessage("End Session");
  setN(4);
  dd(!d);

  setIntro(true);
  setCallibrated(true);
  //console.log('Token:', token);
 // console.log('Token data type:', typeof token);
  const sessionPixelSize = getPixelsPerInch() + 0;
  //console.log(sessionPixelSize);
  //console.log('Token data type:', typeof sessionPixelSize);
  startSession( token, sessionPixelSize);
};

  const[exitButtonMessage, setExitButtonMessage] = useState("Quit Calibration");

  return (
        <>
           {!timerComplete && 
          <div style={{ height: '100%',backgroundColor:"#b2d8d8"}}>
           
            <div style={styles.container}>
                <WebGazerComponent onReady={handleWebGazerReady} />
                <div style={styles.header}>
                  {callibrated && 
                    <div style={styles.innerContainer}>
                      <Score />
                      <Timer onTimerComplete={handleTimerComplete} />
                    </div>
                    }

                  {intro && isGazeTrackerLoaded && 
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      style={styles.endSessionButton}
                      onClick={() => handleTimerComplete(true)}> {exitButtonMessage}
                    </motion.div>
                      }

                </div>
        
              <div id="callibration-container" style={styles.formContainer}>
                {intro && isGazeTrackerLoaded && points.map((point, index) => renderCalibrationPoint(point, index))}
                
              </div>

              {!intro && <InstructionsContainer onStartSession ={handleStartSessionClicked}/>}
              {!isGazeTrackerLoaded && <LoadingComponent />}

              {isWebgazerReady && (
              <GazeTracker points={points} onPointClicked={handlePointClicked} d={d} checkLoaded={checkGazeTrackerLoaded}/>
              )}
            </div> 
          </div> }
          </> 
        );
  };

export default MyCal;