import React, { useState, useEffect, useRef } from 'react';

const getEuclideanDistance = (point1, point2) => {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
};

const GazeTracker = ({ points, onPointClicked ,d, checkLoaded}) => {
  const [gazeStartTime, setGazeStartTime] = useState(performance.now());
  const [totalGazeTime, setTotalGazeTime] = useState(0);
  const chosenPointRef = useRef(null);

  const [gazeSquare, setGazeSquare] = useState(performance.now());
  const firstGazeRef = useRef(true);
  const [totalTestTime, setTotalTestTime] = useState(performance.now());

  
  // Add a useRef to store the interval ID
  const gazeCheckIntervalRef = useRef(null);
  const averageFpsRef = useRef(60); // Default to 60 FPS
  const frameTimestamps = useRef([]);

  // Add a state variable or useRef to store the initial gaze location
  const initialGazeLocationRef = useRef({ x: null, y: null });

  useEffect(() => {
    chosenPointRef.current = points.find(point => point.choosen_point);
    setGazeStartTime(null);
    firstGazeRef.current = true;
   // console.log("hayiir");
    setTotalTestTime(performance.now());

    // Get the current gaze prediction and store the gaze location
    window.webgazer.getCurrentPrediction().then(prediction => {
      if (prediction) {
        const { x, y } = prediction;
        initialGazeLocationRef.current = { x, y };
      }
    });

    // Clear any existing interval
    clearInterval(gazeCheckIntervalRef.current);

    // Set up the gaze check interval based on the average FPS
    const intervalDuration = 1000 / averageFpsRef.current;
    gazeCheckIntervalRef.current = setInterval(checkGazeOnPoint, intervalDuration);

    // Clean up the interval on unmount or when the points change
    return () => clearInterval(gazeCheckIntervalRef.current);
  }, [points]);

  // get the fps and keep updating it.
   useEffect(() => {
    const measureFrameRate = (timestamp) => {
      frameTimestamps.current.push(timestamp);
      // Remove timestamps older than 1 second from the array
      while (frameTimestamps.current.length > 1 && frameTimestamps.current[0] < timestamp - 1000) {
        frameTimestamps.current.shift();
      }
      // Calculate the average frame rate over the last second
      if (frameTimestamps.current.length > 1) {
        const timeDifference = frameTimestamps.current[frameTimestamps.current.length - 1] - frameTimestamps.current[0];
        const currentAverageFps = (frameTimestamps.current.length - 1) / (timeDifference / 1000);
        averageFpsRef.current = currentAverageFps;
      }
      requestAnimationFrame(measureFrameRate);
    };
    requestAnimationFrame(measureFrameRate);
  }, []);

  const checkGazeOnPoint = () => {
    window.webgazer.getCurrentPrediction().then(prediction => {
      if (prediction) {
        const { x, y } = prediction;
        const chosenPoint = chosenPointRef.current;

        if (chosenPoint) {
          const { x: px, y: py, diameter } = chosenPoint;
          const isInSquare =
            x >= px - diameter &&
            x <= px + diameter &&
            y >= py - diameter &&
            y <= py + diameter;

          if (isInSquare) {
              if (firstGazeRef.current) {
                setGazeSquare(performance.now());
                firstGazeRef.current = false;
            //    console.log("hehe");
            }
              if (!gazeStartTime) {
                   setGazeStartTime(performance.now());
            //       console.log("1");
              }
          } else if (gazeStartTime) {
              const gazeDuration = performance.now() - gazeStartTime;
              setTotalGazeTime(totalGazeTime + gazeDuration)
              setGazeStartTime(null);
            } 
        }
      }
    });
  };

  useEffect(() => {

    const timeGazeSquare = (performance.now() - gazeSquare)/1000;
    const totalTime = (performance.now() - totalTestTime)/1000;
    const timeTillGaze = totalTime - timeGazeSquare;
    // Calculate the division factor based on the current average FPS
    const divisionFactor = (averageFpsRef.current) * 100 * 2; // * 2 make the result more accurate since it returns large value

    // Get the chosen point location
    const chosenPoint = chosenPointRef.current;

    if (totalGazeTime >= 0 && onPointClicked) {
     /* console.log("Total test time(s) :")
      console.log(totalTime);

      console.log("Time Till First Gaze(s) :")
      console.log(timeTillGaze);

      console.log("Time After First Gaze(s) :")
      console.log(timeGazeSquare);

      // print avarage fps for the test interval
      console.log("averageFps:")
      console.log(averageFpsRef.current);

      // Print the initial gaze location
      console.log("Initial Gaze Location (x, y):");
      console.log(initialGazeLocationRef.current);

      // Print the initial gaze location
      console.log("Distance to Choosen Point (pixels):");
      console.log(getEuclideanDistance(initialGazeLocationRef.current,chosenPoint) );
      
      // print the total gaze on choosen point, return timeGazeSquare if its less.
      console.log("Total gaze on choosen point(s) :");
      console.log(Math.min(
       (timeGazeSquare), (totalGazeTime / divisionFactor)
      ));

    /*  onPointClicked(Math.min(
       (timeGazeSquare), (totalGazeTime / divisionFactor)
      ));


      totalTime, timeTillGaze, timeGazeSquare, avarageFps.current,
       initialGazeLocationRef.current, getEuclideanDistance(initialGazeLocationRef.current,chosenPoint), Math.min(
       (timeGazeSquare), (totalGazeTime / divisionFactor)
      )
*/
      onPointClicked(totalTime, timeTillGaze, timeGazeSquare, averageFpsRef.current,
       initialGazeLocationRef.current, getEuclideanDistance(initialGazeLocationRef.current,chosenPoint), Math.min(
       (timeGazeSquare), (totalGazeTime / divisionFactor)
      ))
     // onPointClicked(timeGazeSquare);
      setTotalGazeTime(0);
    }
  }, [d]);

   useEffect(() => {
    if (checkLoaded) {
      checkLoaded();
    }
  }, []);

  return null;
};

export default GazeTracker;