import React ,{useState,useEffect} from 'react';
import "../LoggedNavigation/LoggedCss.css";
import { ReactComponent as Logo } from '../logo.svg';
import { FaRegEnvelope, FaInstagramSquare, FaTwitter, FaTiktok } from 'react-icons/fa';
import { motion } from 'framer-motion';
import t from './t.jpeg';
import tt from './tt.jpeg';
import ttt from './ttt.jpeg';
import tttt from './tttt.jpeg';

import SessionData from "./MyData/SessionData";

const MyData = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="formContentDiv" style={{ backgroundColor: '#196262',color:"#f8f8f8" }}>
     <motion.div animate={{ scale: [1.03, 1] }}
                  transition={{ ease: "easeIn", duration: 0.3 }} style={{ 
    height:"100%",
    width:"90%",
    padding:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }}>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#b2d8d8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto',fontWeight:700, fontSize: '65px', marginBottom: '5px' }}>
         Attention Visual My Data
        </motion.div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <img src={tt} alt="Gaze tracking illustration" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }} />
</div>
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Welcome to the myData page! Here, you can view your personal progress and performance in Attention Visual. 
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
          Explore the interactive Session Score vs Session Date graph to visualize your session scores over time. This helps you identify trends and patterns in your learning. Zoom in on specific date ranges by clicking and dragging on the graph. When you hover over data points, you'll see detailed information about that specific session. Click on a session point to view a summary of the tests within that session in a pop-up modal.
<br></br><br></br>
In the session details modal, you'll find a bar chart representing the total time spent on each test. As you hover over each bar, more information about the test is revealed, such as difficulty, illusion, and gaze on point. Please note that the bars in the Session Summary chart are not clickable.
<br></br><br></br>
We encourage you to regularly review your myData page to gain insights into your visual cognition development, and use this information to guide your continued improvement and make your learning experience more enjoyable.. 
<br></br><br></br>
If you have any questions or need assistance, feel free to reach out to our support team at <span style={{fontWeight:400,wordBreak: 'break-all'}}>support@attentionvisual.com</span>.
<br></br><br></br>
Happy learning!
            </motion.div>
            {screenWidth > 900 ? (
<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
        <motion.div  style={{backgroundColor:"#f8f8f8",borderRadius:"30px",display: 'flex', width: '100%', height: '100%',
        justifyContent: 'center', textAlign: "center", minWidth:"350px"  }}>
          <motion.div  style={{width: '90%', height: '90%' }}>
          <SessionData/>
          </motion.div>
        </motion.div>
        </div> ) : (
  <div style={{ fontFamily: 'Roboto,serif', fontWeight:400, fontSize: '16px', textAlign: 'center', marginBottom: '20px',color:"#FD0B0B" }}>
    Your screen width is not large enough. Please use a larger device to access this feature.
  </div>
)}


        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
        </motion.div>
        
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  

          
        </div>

      <div style={{
  borderTop: '1px solid #b2d8d8',
  padding: '20px',
  textAlign: 'center',
  fontFamily: 'Roboto Mono',
  fontWeight:400,
}}>
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}></div>
<motion.div whileHover={{ scale: 1.02 }} style={{ whiteSpace: 'nowrap', margin: '18px',marginBottom:"5px" }}>
      Contact Us:
    </motion.div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontFamily: 'Roboto', flexWrap: 'wrap' }}>
    
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaRegEnvelope style={{ color: "#D66532", fontSize: "25px" }} /> <a style={{color:"#D66532"}} href="mailto:info@attentionvisual.com">&nbsp;info@attentionvisual.com</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaInstagramSquare style={{ color: "#C13584", fontSize: "25px" }} /> <a style={{color:"#C13584"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTiktok style={{color:"#000000", fontSize:"25px"}}/> <a style={{color:"#000000"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTwitter style={{ color: "#1DA1F2", fontSize: "25px" }} /> <a style={{color:"#1DA1F2"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>

    <motion.div whileHover={{ scale: 1.05 }} style={{ whiteSpace: 'nowrap', margin: '18px' }}>
                  <div

                      

                       style={{
                              fontSize: '18px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '500',
                              color:"f8f8f8"
                             }}

                   
                    >
      <Logo style={{ width: '45px', height: '45px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '45px', height: '45px', transform: 'scaleX(-1)' }}
      />
    </div>
  </motion.div>
  </div>
                  
</div>
       
      </motion.div>
    </div>
  );
};
export default MyData;