import React from 'react';
import "../LoggedNavigation/LoggedCss.css";
import { ReactComponent as HomeTitle } from './home.svg';
import { ReactComponent as Logo } from '../logo.svg';
import { FaRegEnvelope, FaInstagramSquare, FaTwitter, FaTiktok } from 'react-icons/fa';
import { motion } from 'framer-motion';
import t from './t.jpeg';
import tt from './tt.jpeg';
import ttt from './ttt.jpeg';
import tttt from './tttt.jpeg';

import { RiEyeCloseFill, RiMore2Fill, RiMoreFill,
          RiDatabase2Fill,RiHome4Fill,RiFocus2Line, RiInformationLine,
          RiSettings5Fill, RiLogoutBoxRLine } from "react-icons/ri";


import { ReactComponent as WhiteLogo } from '../buttonEye.svg';

const Home = () => {
  const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']
  return (
    <div className="formContentDiv" style={{ backgroundColor:'#165858',color:"#f8f8f8" }}>
       <motion.div animate={{ scale: [1.03, 1] }}
                  transition={{ ease: "easeIn", duration: 0.3 }} style={{ 
    height:"100%",
    width:"90%",
    padding:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }}>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#b2d8d8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto',fontWeight:700, fontSize: '60px', marginBottom: '5px' }}>
         Welcome to Attention Visual
        </motion.div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <img src={tttt} alt="Gaze tracking illustration" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }} />
</div>
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '35px', marginBottom: '20px' }}>
          We're glad to have you on board. With Attention Visual, you can participate in gaze tracking tests, gain insights into visual attention and cognitive patterns, and contribute to the advancement of this exciting field.
        </motion.div>
        
       
        <motion.div style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '22px', textAlign: "center", marginBottom: '50px' }}>
           As a registered user, you can:<br></br><br></br>
<motion.span whileHover={{ fontSize: '24px', color: "#f8f8f8"}} >
<WhiteLogo style={{width:"30px",height:"30px"}}/> Participate in a variety of gaze tracking tests.<br></br><br></br></motion.span>
<motion.span whileHover={{ fontSize: '24px', color: "#f8f8f8"}} >
<WhiteLogo style={{width:"30px",height:"30px"}}/>  Access real-time data visualization to better understand your visual attention patterns.<br></br><br></br></motion.span>
<motion.span whileHover={{ fontSize: '24px', color: "#f8f8f8"}} >
<WhiteLogo style={{width:"30px",height:"30px"}}/>  Stay up-to-date with the latest developments and improvements in the platform.<br></br><br></br></motion.span>

We're continually working to enhance Attention Visual and bring you more features, including advanced data analysis and customizable tests. Stay tuned for updates!

        </motion.div>
<motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto,serif',fontWeight:200, fontSize: '20px', marginBottom: '20px' }}>
        To begin, use the navigation bar to explore different sections of the platform:
        </motion.div>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  <div style={{ display: "grid", justifyContent: "center", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gridGap: "20px", justifyItems: "center"}}>
    {[
      {
        title: "MyApp",
        text: "Participate in gaze tracking tests by launching our gaze tracking app.",
        icon: <RiFocus2Line style={{ width:"50px",height:"50px", color:colors[0]}}/>,
      },
      {
        title: "MyData",
        text: "View your progress and access real-time data visualization.",
        icon: <RiDatabase2Fill style={{ width:"50px",height:"50px", color:colors[1]}}/>,
      },
      {
        title: "Info",
        text: "Learn more about our platform and find support.",
        icon: <RiInformationLine style={{ width:"50px",height:"50px", color:colors[2]}}/>,
      },
      {
        title: "Settings and Logout",
        text: "Access your user settings and log out by clicking on the button at the top right corner of the screen.",
        icon: <RiMore2Fill style={{ width:"50px",height:"50px", color:colors[3]}}/>,
      },
    ].map((feature, index) => (
      <motion.div key={index} whileHover={{ scale: 1.15 }} style={{ width: "200px", padding: "10px", display: "grid", gridTemplateRows: "100px auto auto", textAlign: "center", fontFamily: 'Karla', fontSize: '14px', marginBottom: "20px" }}>
        <div style={{ marginBottom: "10px", alignSelf: "center", justifySelf: "center" }}>
          {feature.icon}
        </div>
        <div style={{ fontWeight: '700', fontSize: "16px", marginBottom: '5px', alignSelf: "start" }}>{feature.title}<br></br>
          <span style={{ fontWeight: '400', color: "#b2d8d8" }}>{feature.text}</span>
        </div>
      </motion.div>
    ))}
  </div>
</div>
        
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  

          
        </div>

      <div style={{
  borderTop: '1px solid #b2d8d8',
  padding: '20px',
  textAlign: 'center',
  fontFamily: 'Roboto Mono',
  fontWeight:400,
}}>
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}></div>
<motion.div whileHover={{ scale: 1.02 }} style={{ whiteSpace: 'nowrap', margin: '18px',marginBottom:"5px" }}>
      Contact Us:
    </motion.div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontFamily: 'Roboto', flexWrap: 'wrap' }}>
    
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaRegEnvelope style={{ color: "#D66532", fontSize: "25px" }} /> <a style={{color:"#D66532"}} href="mailto:info@attentionvisual.com">&nbsp;info@attentionvisual.com</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaInstagramSquare style={{ color: "#C13584", fontSize: "25px" }} /> <a style={{color:"#C13584"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTiktok style={{color:"#000000", fontSize:"25px"}}/> <a style={{color:"#000000"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTwitter style={{ color: "#1DA1F2", fontSize: "25px" }} /> <a style={{color:"#1DA1F2"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>

    <motion.div whileHover={{ scale: 1.05 }} style={{ whiteSpace: 'nowrap', margin: '18px' }}>
                  <div

                      

                       style={{
                              fontSize: '18px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '500',
                              color:"f8f8f8"
                             }}

                   
                    >
      <Logo style={{ width: '45px', height: '45px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '45px', height: '45px', transform: 'scaleX(-1)' }}
      />
    </div>
  </motion.div>
  </div>
                  
</div>
       
      </motion.div>
    </div>
  );
};

export default Home;