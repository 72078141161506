import React,{ useState,useEffect,useContext} from "react";
import { Form, Card , Alert, Modal} from "react-bootstrap";
import {FaBirthdayCake, FaTransgender}  from "react-icons/fa";
import { StateContext } from "../../contexts";
import { useResource } from "react-request-hook";
import { motion } from 'framer-motion';
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";

import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';

import TermsAndConditions from "./TermsAndConditions";

const styles = {

 formControlInput: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    color: '#001E1E',
    outline: "none",
    borderBottom: "0.1vw solid #445366",
    fontSize: "19px",
    fontFamily: 'Roboto, serif',
    fontWeight: "300",
    paddingBottom: "10px",
    marginTop: "10px",
    minHeight: "45px", 
  },
  button: {

    width: "160px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "#008080",
    border:"none",
    color: '#f8f8f8',
    fontFamily: 'Roboto, serif',
    fontWeight: "700",
    outline:"none",
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '20px',
  },


  formGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "3px",
    marginTop: "3px",


  },
  formLabel: {
    backgroundColor: "transparent",
    border: "none",
    color: "#006666",
    outline: "none",
    fontWeight: "300",
    paddingBottom: "3px",
    marginTop: "8px",
    fontSize: "25px", 
    marginRight: "10px",
    width:"20%",
  },
    signUp: {
    position: "absolute",
    top: "5.5vw",
    right: "1.2vw",
    width: "20%",
  },
  label: {
    fontSize: '12px',
    fontWeight: '400',
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: "30px",
   
  },
};

export default function SignUp() {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [lastname, setLastname] = useState('');
  const [birthday,setBirthday] = useState('');
  const [email,setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password,setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);

  const { dispatch } = useContext(StateContext);

  const [user, signup] = useResource((name, lastname, email,password, gender,birthday) => ({
    url: "/signup",
    method: "post",
    data: { name, lastname, email,password, gender,birthday},
  }));
const navigate = useNavigate();
useEffect(() => {
    if (user?.data?.token) {
      setLoginFailed(false);
      dispatch({ type: "REGISTER", token : user.data.token});
     // console.log(user.data.token);

       // Store the token in localStorage
    localStorage.setItem("userToken", user.data.token);
    navigate("/home");
    }

    if (user?.error) {
    //  console.log(user?.error);
      setLoginFailed(true);
    }
  }, [user]);

  const handleSignUp = (e) => {

    const birthdayDate = new Date(birthday);

    const minDate = new Date("1920-04-23");
    const todayDate = new Date();
    const thirteenYearsAgo = new Date();

    thirteenYearsAgo.setFullYear(todayDate.getFullYear() - 13);
    

    if(emailRegex.test(email) &&!(name.length===0|| lastname.length===0|| email.length===0||
        password.length<8|| gender.length===0||birthday.length===0|| 
        confirmPassword ===0 || confirmEmail===0 ||
        birthdayDate < minDate ||
        birthdayDate > thirteenYearsAgo || !agreeTerms ||  !passwordRegex.test(password))) {
      e.preventDefault();
      signup(name, lastname, email,password, gender,birthday);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowConfirmPassword = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };

const getFontSize = (textLength) => {
  const defaultFontSize = 20;
  const minFontSize = 8;
  const charactersPerStep = 3;
  const characterThreshold = 20;

  if (textLength <= characterThreshold) {
    return defaultFontSize;
  }

  let decreaseFactor = Math.floor((textLength - characterThreshold) / charactersPerStep)+2;
  let calculatedFontSize = defaultFontSize - decreaseFactor;

  return calculatedFontSize > minFontSize ? calculatedFontSize : minFontSize;
};

const getInputStyle = (emailLength) => {
  return {
    ...styles.formControlInput,
    fontSize: `${getFontSize(emailLength)}px`,
  };
};

const handleKeyPress = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

const todayDate = new Date();
const thirteenYearsAgo = new Date();

thirteenYearsAgo.setFullYear(todayDate.getFullYear() - 13);

const today_thirteen = thirteenYearsAgo.toISOString().split("T")[0];
const [agreeTerms, setAgreeTerms] = useState(false);

const [showTermsModal, setShowTermsModal] = useState(false);

const handleTermsOpen = () => {
  setShowTermsModal(true);
};

const handleTermsClose = () => {
  setShowTermsModal(false);
};

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/;

  return (
  <motion.div className="position-absolute" whileHover={{ scale: 1.03 }} initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.3 }} // Set the duration of the animation>
          >
    <Card className="shadow-lg rounded" style={{width:"300px" ,backgroundColor: '#f8f8f8',maxHeight: '75vh',
    overflowY: 'auto',}}>
      <Card.Body style={{color:'#001E1E'}}>
        <Form>
          <motion.div whileHover={{ scale: 1.03 }}>
          <Form.Control
            style={getInputStyle(name.length)}
            type="text"
            placeholder="Enter first name"
            size="sm"
            value ={name}
            onChange= {(e) => setName(e.target.value)}
            
            required
          /></motion.div>
          <motion.div whileHover={{ scale: 1.03 }}>
          <Form.Control
            style={getInputStyle(lastname.length)}
            type="text"
            placeholder="Enter last name"
            size="sm"
            value={lastname}
            onChange= {(e) => setLastname(e.target.value)}
            
            required
          /></motion.div>
          <motion.div whileHover={{ scale: 1.03 }}>
          <Form.Control
            style={getInputStyle(email.length)}
            type="email"
            placeholder="Enter email"
            size="sm"
            value={email}
            onChange= {(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            pattern={emailRegex.source} // Use the emailRegex pattern for validation
            title="Please enter a valid email address."
            required
          /></motion.div>

        <Form.Group controlId="confirmEmail">
        <motion.div whileHover={{ scale: 1.03 }}>
          <Form.Control
            style={getInputStyle(confirmEmail.length)}
            type="email"
            placeholder="Confirm email"
            size="sm"
            value={confirmEmail}
            onChange= {(e) => setConfirmEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            required
          isInvalid={email!==confirmEmail}
            />
            <Form.Control.Feedback style={{ color: '#FD0B0B', marginTop: '4px',fontFamily: 'Roboto, serif',
    fontWeight: "400", }} type="invalid">
              Emails don't match
            </Form.Control.Feedback>
            </motion.div>
          </Form.Group>
          
          <motion.div style={{ display: "flex",
  alignItems: "center"}} whileHover={{ scale: 1.03 }}>

  <Form.Control
  style={getInputStyle(password.length)}
  type={showPassword ? "text" : "password"}
  placeholder="Password"
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  onKeyPress={handleKeyPress}
  required
  minLength={8} // Minimum length of 8 characters
  pattern = {passwordRegex.source}
  title="Password must be at least 8 characters long and include a number, capital letter, and special character."
/>

  <motion.button
    type="button"
    onClick={handleShowPassword}
    style={{color: '#001E1E',backgroundColor:'#f8f8f8',border:"none"}}
    whileHover={{ scale: 1.1 }}
  >
    {showPassword ? <RiEyeFill /> : <RiEyeCloseFill />}
  </motion.button>
</motion.div>
  <motion.div  whileHover={{ scale: 1.03 }}>
      <Form.Group controlId="confirmPassword">
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <Form.Control
        style={getInputStyle(confirmPassword.length)}
        type={showConfirmPassword ? "text" : "password"}
        placeholder="Confirm password"
        size="sm"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        required
        isInvalid={password !== confirmPassword}
      />
      <motion.button
    type="button"
    onClick={handleShowConfirmPassword}
    style={{color: '#001E1E',backgroundColor:'#f8f8f8',border:"none"}}
    whileHover={{ scale: 1.1 }}
  >
        {showConfirmPassword ? <RiEyeFill /> : <RiEyeCloseFill />}
      </motion.button>
    </div>
    {password !== confirmPassword && (
      <Form.Text style={{ color: '#FD0B0B', marginTop: '4px',fontFamily: 'Roboto, serif',
    fontWeight: "400", }}>Passwords don't match</Form.Text>
    )}
  </div>
</Form.Group></motion.div>

          <motion.div whileHover={{ scale: 1.03 }}>
          <Form.Group style={styles.formGroup}>

              <Form.Label style={styles.formLabel} className="text-center">
                  <FaBirthdayCake/>
              </Form.Label>
              
              <Form.Control
                  style={{...styles.formControlInput,minHeight: "40px" , width:"80%",marginTop:"2px",}}
                  type="date"
                  size="sm"
                  value={birthday}
                  onChange= {(e) => setBirthday(e.target.value)}
                  min="1920-04-23"
                  max={today_thirteen} 
                  required
                  />
          </Form.Group> </motion.div>

          <motion.div whileHover={{ scale: 1.03 }}>

          <Form.Group controlId="gender" style={{...styles.formGroup,borderBottom: "0.1vw solid #445366"} }>
             
              <Form.Label style={{...styles.formLabel,width:"35%",marginTop:"none", fontSize:"40px"}} 
                          className="text-center">
                        <FaTransgender />
              </Form.Label>
          
              <div style={{...styles.formGroup, marginTop:"none",display:"inline",width:"20%"}}>
          
                  <motion.div whileHover={{ scale: 1.05 }}> <Form.Check
                    style={{...styles.formControlInput,minHeight: "20px" ,width:"10%", borderBottom:"none", fontSize: "12px",
            fontWeight: "400",marginLeft:"none", marginTop: "none",}}
                    type="radio"
                    id="male"
                    label="Male"
                    name="gender"
                    value="male"
                    className="text-center" 
                    checked={gender === 'male'}
                    onChange= {(e) => setGender(e.target.value)}
                    required
                    inline
                    /></motion.div>

                 <motion.div whileHover={{ scale: 1.05 }}> <Form.Check
                    style={{...styles.formControlInput,minHeight: "20px", width:"10%", borderBottom:"none", fontSize: "12px",
            fontWeight: "400",marginLeft:"none", marginTop: "none"}}
                    type="radio"
                    id="female"
                    label="Female"
                    name="gender"
                    value="female"
                    className="text-center"
                    checked={gender === 'female'}
                    onChange= {(e) => setGender(e.target.value)}
                    required
                    inline
                    /></motion.div>

                 <motion.div whileHover={{ scale: 1.05 }}> <Form.Check
                    style={{...styles.formControlInput,minHeight: "20px",  width:"10%", borderBottom:"none", fontSize: "12px",
            fontWeight: "400",marginLeft:"none", marginTop: "none"}}
                    type="radio"
                    id="other"
                    label="Other"
                    name="gender"
                    value="other"
                    className="text-center"
                    checked={gender === 'other'}
                    onChange= {(e) => setGender(e.target.value)}
                    required
                    inline
                  /></motion.div>

                  </div>
          </Form.Group> </motion.div>

          <div className="text-center mt-3">
            {loginFailed && (<Alert variant="danger" style={{marginTop:'5px'}} >User already exists with that email</Alert>)}
        </div>

 



<motion.div whileHover={{ scale: 1.04 }}>
  <Form.Group style={styles.formGroup}>
    <div style={styles.termsContainer}>
      <Form.Check
        style={{
          minHeight: '20px',
          borderBottom: 'none',
          
          marginLeft: 'none',
          marginTop: 'none',
          fontFamily: 'Roboto, serif',
          fontWeight: "500",
          fontSize: '12px',
          
          color:"#66b2b2",
        }}
        type="checkbox"
        id="agreeTerms"
        checked={agreeTerms}
        onChange={(e) => setAgreeTerms(e.target.checked)}
        required
      />
      <Form.Check.Label htmlFor="agreeTerms" style={styles.label}>
        I agree to the &nbsp;
      </Form.Check.Label>
      <motion.button
        onClick={handleTermsOpen}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          textDecoration: 'underline',
          
          cursor: 'pointer',
          fontFamily: 'Roboto, serif',
          fontWeight: "700",
          fontSize: '12px',
          
          color:"#66b2b2",
        }}
        whileHover={{ scale: 1.05 }}
      >
        Terms and Conditions
      </motion.button>
    </div>
  </Form.Group>
</motion.div>

<Modal show={showTermsModal} onHide={handleTermsClose} style={{
    maxHeight: "70%",
    overflowY: "auto",
    marginTop: "50px",
     borderRadius: "5px", // Add this line
    
  }}>
  <Modal.Header  style={{
    display: "flex",
    justifyContent: "center",
  }}
>
    <Modal.Title style={{fontFamily: 'Roboto Mono, serif',
          fontWeight: "500",
          fontSize: '25px',
          color:"#008080",}}>Terms and Conditions</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <TermsAndConditions/>
  </Modal.Body>
  <Modal.Footer
  style={{
    display: "flex",
    justifyContent: "center",
  }}
>
  <div style={{ height: "70px" }}>
    <motion.button
      style={{...styles.button,fontWeight:"400"}}
      className="mt-4 border-0 text-center"
      type="submit"
      onClick={handleTermsClose}
      whileHover={{ scale: 1.1 }}
    >
      I understand
    </motion.button>
  </div>
</Modal.Footer>
</Modal>

         <div style={{ height: "70px" }}>
  
            <motion.button
              style={styles.button}
              className= "mt-4 border-0 text-center"
              type="submit"
              onClick={handleSignUp}
              whileHover={{ scale: 1.1 }}
              disabled={
                password!==confirmPassword ||
                email!==confirmEmail
              }
            >SIGN UP
            </motion.button>
          </div>
        </Form>

      </Card.Body>
    </Card>
  </motion.div>
  );
}
