import React,{useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from './logo.svg';
import './TextContainer.css'; // Import the CSS file
import { RiEyeFill, RiEyeCloseFill } from 'react-icons/ri';

const styles = {
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "transparent",
  },
  textContainer: {
    backgroundColor: '#f8f8f8',
    color: '#001E1E',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '90vh',
    overflowY: 'auto',
    margin: '5vh 0',
    fontSize: '1.2rem',
    fontFamily: 'Roboto, serif',
    fontWeight: 400,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
   // boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.15)', // Add a subtle shadow

  },
 
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '20px',
  },

  startssButton: {
    backgroundColor: '#008080',
    color: '#F8F8F8',
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto Mono, serif',
    fontWeight: 500,
    width: 'fit-content',
  },
  startButton: {
    // ... other styles
    backgroundColor: '#008080',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
  },
  goBackButton: {
    // ... other styles
     // ... other styles
    backgroundColor: '#FD0B0B',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow

  },
  logoStyle: {
    width: '100px',
    height: '100px',
    marginBottom: '10px',
  },
  numberStyle: {
    color: '#e30909',
    fontWeight: 500,
  },
};


const useColorCycle = (colors, duration) => {
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, duration);

    return () => {
      clearInterval(interval);
    };
  }, [colors, duration]);

  return colors[colorIndex];
};

const GazeCalibrationTextContainer = ({ contCalibration,goBack }) => {

  const colors = ['#FD0B0B', '#f96c22', '#fdeb31', '#7bc043', '#0392cf', '#a660cf','#808080'];
  const currentColor = useColorCycle(colors, 2000); // Change the color every 1000ms (1 second)
  const isGray = currentColor === '#808080';

  const handleClick = () => {
    contCalibration();
  };

  const handleBack = () => {
    goBack();
  }
 
  const circlePath = 'M50,25 A25,25 0 0,1 50,0 A25,25 0 0,1 50,50';
  const squarePath = 'M25,0 L75,0 L75,50 L25,50 L25,0';
  const trianglePath = 'M25,50 L75,50 L50,0 L25,50';

  const shapeVariants = {
    circle: { d: circlePath },
    square: { d: squarePath },
    triangle: { d: trianglePath },
  };

  return (
    <div style={styles.formContainer}>
      <motion.div
        style={styles.textContainer}
        className="text-container"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.03 }}
      >
        <motion.div initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ flexShrink: 0 }}>
          <Logo style={styles.logoStyle} />
        </motion.div>
        
        <motion.h2 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{color:"#FD0B0B", fontFamily: 'Roboto Mono, serif',fontWeight: 500,}}>Calibration Magic</motion.h2>

        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
          <span style={styles.numberStyle}>1.</span> Get ready to calibrate the gaze tracker algorithm! You will see a screen with a bunch of points scattered around.
        </motion.p>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
          <span style={styles.numberStyle}>2.</span> Simply click on each point with your mouse or tap with your finger. Watch them magically turn gray as you click on them! 🌪️
        </motion.p>
         
         <motion.div initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }}  >
          <motion.div
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: currentColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          animate={{ backgroundColor: currentColor }}
          transition={{ duration: 1, ease: 'anticipate' }}
          whileHover={{scale: 1.1}}

        >
          {isGray ? (
            <RiEyeCloseFill style={{color: '#001E1E',}} size={24} color="#FFFFFF" />
          ) : (
            <RiEyeFill style={{color: '#001E1E',}} size={24} color="#FFFFFF" />
          )}
        </motion.div></motion.div>

        


        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
          <span style={styles.numberStyle}>3.</span> Continue clicking until ALL the points turn gray. This means the calibration is complete!
        </motion.p>
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
          <span style={styles.numberStyle}>4.</span> Say hello to Gaze Turtle, &nbsp;
          <Logo style={{width: '25px', height: '25px'}} />
          !! It's the fancy icon that shows where you're looking on the screen. After calibration, 
          &nbsp;<Logo style={{width: '25px', height: '25px'}} /> will appear and follow your gaze. Keep in mind, 
          &nbsp;<Logo style={{width: '25px', height: '25px'}} /> can't move as fast as your eyes, but it's trying its best to keep up!
        </motion.p>

        <div style={styles.buttonsContainer}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.goBackButton}
            onClick={handleBack}
          >
            Go Back
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.startButton}
            onClick={handleClick}
          >
            Continue
          </motion.div>
        </div>

      </motion.div>
    </div>
  );
};

export default GazeCalibrationTextContainer;