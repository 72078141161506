import React from "react";
import { motion } from "framer-motion";
import { RiEyeFill } from "react-icons/ri";
import { ReactComponent as Logo } from './logo.svg';


const loadingContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 99999,
};

const logoAndTextContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "30%"
};


const loadingIconStyle = {
  fontSize: "11.25rem",
  color: "#001E1E",
};

const dotContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "-3rem",
};

const dotStyle = {
  fontSize: "7.5rem",
  marginLeft: "0.25rem",
  marginRight: "0.25rem",
  color: "#001E1E",
};

const dotVariants = {
  initial: { y: 0, opacity: 0 },
  animate: { y: -50, opacity: 1 },
};

const LoadingComponent = () => {
  const renderDots = () => {
    return [0, 1, 2].map((index) => (
      <motion.span
        key={index}
        style={dotStyle}
        variants={dotVariants}
        initial="initial"
        animate="animate"
        transition={{
          duration: 0.75,
          delay: index * 0.5,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        .
      </motion.span>
    ));
  };

  return (
    <div style={loadingContainerStyle}>
     <div style={logoAndTextContainerStyle}>
        <motion.div
          style={loadingIconStyle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <Logo style={{ width: '450px', height: '450px' }} />
        </motion.div>
        <motion.div style={{ fontFamily: 'Roboto Mono, serif', 
                     color: '#001E1E', 
                     fontSize: '32px', 
                     fontWeight: 700 }}
                     initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, loop: Infinity }}>
          ATTENTION VISUAL
        </motion.div>
        <div style={dotContainerStyle}>{renderDots()}</div>
      </div>
      
    </div>
  );
};

export default LoadingComponent;