import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../contexts";
import "../LoggedNavigation/LoggedCss.css";
import AttentionVisual from "../GazeApp/AttentionVisual"
import { ReactComponent as Logo } from '../logo.svg';
import { FaRegEnvelope, FaInstagramSquare, FaTwitter, FaTiktok } from 'react-icons/fa';
import { motion } from 'framer-motion';
import t from './t.jpeg';
import tt from './tt.jpeg';
import ttt from './ttt.jpeg';
import tttt from './tttt.jpeg';

const styles = {

  formControlInput: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    color: "#003333",
    outline: "none",
    borderBottom: "0.1vw solid #445366",
    fontSize: "20px",
    fontFamily: 'Roboto, serif',
    fontWeight: "300",
    paddingBottom: "10px",
    marginTop: "10px",
    minHeight: "47px", 
  },
   button: {
    width: "250px",
    height: "50px",
    fontSize: "18px",
    backgroundColor: "#005151",
    color: '#f8f8f8',
    fontFamily: 'Roboto, serif',
    fontWeight: "700",
    outline:"none",
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '25px',
    marginBottom:"50px"
  },
};
const MyApp = () => {

  const { state, dispatch } = useContext(StateContext);
  const { appState } = state;

  const handleClick = () => {
    dispatch({ type: 'SET_APP_TRUE' });
  };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {appState && (
      <div style={{height:'100vh', backgroundColor:"#b2d8d8"}}>
        <AttentionVisual />
      </div>
      )} 


      {!appState && (
        <div className="formContentDiv" style={{ backgroundColor: '#206d6d',color:"#f8f8f8" }}>
           <motion.div animate={{ scale: [1.03, 1] }}
                  transition={{ ease: "easeIn", duration: 0.3 }} style={{ 
    height:"100%",
    width:"90%",
    padding:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }}>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#b2d8d8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto',fontWeight:700, fontSize: '60px', marginBottom: '5px' }}>
         Attention Visual Application
        </motion.div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <img src={t} alt="Gaze tracking illustration" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }} />
</div>
       <motion.div whileHover={{ scale: 1.01 }} style={{ fontFamily: 'Comfortaa',fontWeight:600,  fontSize: '30px', textAlign: 'center', marginBottom: '22px' }}>
  The MyApp section is your gateway to participating in various gaze tracking tests. By clicking the button below, you'll launch our state-of-the-art gaze tracking app, which uses advanced AI and machine learning techniques to collect valuable data about your visual attention and cognitive patterns.
</motion.div>
         
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '20px', textAlign: "center", marginBottom: '20px' }}>
  Engaging in these tests will not only help you better understand your visual attention but also contribute to the continuous improvement of our platform. As you participate, our platform will learn and adapt, providing increasingly accurate results and a more refined user experience.
        </motion.div>

       
       {screenWidth > 900 ? (
        <>
<div style={{ fontFamily: 'Roboto,serif', fontWeight:500, fontSize: '18px', textAlign: 'center', marginBottom: '5px',color:"#f8f8f8" }}>
Please ensure that you have <span style={{fontWeight:700}}>disabled any ad or tracker blockers</span> to view our content. If you have already disabled them, refresh the page. </div>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <motion.button
      style={{ ...styles.button }}
      type="button"
      className="btn-block mt-4 border-0"
      onClick={handleClick}
      whileHover={{ scale: 1.1 }}
    >
      Launch the Application
    </motion.button>
  </div>
  </>
) : (
  <div style={{ fontFamily: 'Roboto,serif', fontWeight:400, fontSize: '16px', textAlign: 'center', marginBottom: '20px',color:"#FD0B0B" }}>
    Your screen width is not large enough. Please use a larger device to access this feature.
  </div>
)}
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  

          
        </div>

      <div style={{
  borderTop: '1px solid #b2d8d8',
  padding: '20px',
  textAlign: 'center',
  fontFamily: 'Roboto Mono',
  fontWeight:400,
}}>
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}></div>
<motion.div whileHover={{ scale: 1.02 }} style={{ whiteSpace: 'nowrap', margin: '18px',marginBottom:"5px" }}>
      Contact Us:
    </motion.div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontFamily: 'Roboto', flexWrap: 'wrap' }}>
    
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaRegEnvelope style={{ color: "#D66532", fontSize: "25px" }} /> <a style={{color:"#D66532"}} href="mailto:info@attentionvisual.com">&nbsp;info@attentionvisual.com</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaInstagramSquare style={{ color: "#C13584", fontSize: "25px" }} /> <a style={{color:"#C13584"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTiktok style={{color:"#000000", fontSize:"25px"}}/> <a style={{color:"#000000"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTwitter style={{ color: "#1DA1F2", fontSize: "25px" }} /> <a style={{color:"#1DA1F2"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>

    <motion.div whileHover={{ scale: 1.05 }} style={{ whiteSpace: 'nowrap', margin: '18px' }}>
                  <div

                      

                       style={{
                              fontSize: '18px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '500',
                              color:"f8f8f8"
                             }}

                   
                    >
      <Logo style={{ width: '45px', height: '45px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '45px', height: '45px', transform: 'scaleX(-1)' }}
      />
    </div>
  </motion.div>
  </div>
                  
</div>
       
      </motion.div>
    </div>
      )}
   </>
  );
}

export default MyApp;