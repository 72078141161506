import React,{useState,useContext,useEffect} from "react";
import { Form, Card, Alert} from "react-bootstrap";
import { StateContext } from "../../contexts";
import { useResource } from "react-request-hook";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { motion } from 'framer-motion';

import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';

const styles = {

  formControlInput: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    color: '#001E1E',
    outline: "none",
    borderBottom: "0.1vw solid #445366",
    fontSize: "20px",
    fontFamily: 'Roboto, serif',
    fontWeight: "300",
    paddingBottom: "10px",
    marginTop: "10px",
    minHeight: "47px", 
  },
   button: {
    width: "160px",
    height: "45px",
    fontSize: "18px",
    backgroundColor: "#008080",
    border:"none",
    color: '#f8f8f8',
    fontFamily: 'Roboto, serif',
    fontWeight: "700",
    outline:"none",
    display:"flex",
    justifyContent:"center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: '20px',
  },
  forgotButton:{
    backgroundColor: "transparent",
    fontFamily: 'Roboto, serif',
    fontWeight: "400",
    color:"#66b2b2",
    border: "none",
    outline: "none",
    fontSize: "15px",

  },
};

export default function Login(props) {

  const [email,getEmail] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const [password,getPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const { dispatch } = useContext(StateContext);

  const [user, login] = useResource((email, password) => ({
    url: "/login",
    method: "post",
    data: { email, password },
  }));

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.data?.token) {
      setLoginFailed(false);
      dispatch({ type: "LOGIN", token : user.data.token});
     // console.log(user.data.token);

      // Store the token in localStorage
    localStorage.setItem("userToken", user.data.token);
    navigate("/home");
    }

    if (user?.error) {
      console.log(user?.error);
      setLoginFailed(true);
    }
  }, [user]);


 function handleClick() {
    props.onButtonClick();
  }

  const handleLogin = (e) => {
  
  if(email.includes('@')&&!(email.length===0||password.length===0)){
    e.preventDefault();
    login(email,password)
  }

  };


  const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };

const getFontSize = (textLength) => {
  const defaultFontSize = 20;
  const minFontSize = 8;
  const charactersPerStep = 3;
  const characterThreshold = 20;

  if (textLength <= characterThreshold) {
    return defaultFontSize;
  }

  let decreaseFactor = Math.floor((textLength - characterThreshold) / charactersPerStep)+2;
  let calculatedFontSize = defaultFontSize - decreaseFactor;

  return calculatedFontSize > minFontSize ? calculatedFontSize : minFontSize;
};

const getInputStyle = (emailLength) => {
  return {
    ...styles.formControlInput,
    fontSize: `${getFontSize(emailLength)}px`,
  };
};

const handleKeyPress = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

  return (
    <motion.div className="position-absolute"  whileHover={{ scale: 1.03 }} initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.3 }} // Set the duration of the animation>
          >
      <Card className="shadow-lg rounded" style={{width:"300px" ,backgroundColor: '#f8f8f8',}}>
        <Card.Body>
          <Form>
            <motion.div whileHover={{ scale: 1.03 }}>
            <Form.Control
              style={getInputStyle(email.length)}
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => getEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              required
            /></motion.div>
         

           <motion.div style={{ display: "flex",
  alignItems: "center"}} whileHover={{ scale: 1.03 }}>

  <Form.Control
    style={getInputStyle(password.length)}
    type={showPassword ? "text" : "password"}
    placeholder="Password"
    value={password}
    onChange={(e) => getPassword(e.target.value)}
    onKeyPress={handleKeyPress}
    required
  />

  <motion.button
    type="button"
    onClick={handleShowPassword}
    style={{color: '#001E1E',backgroundColor:'#f8f8f8',border:"none"}}
    whileHover={{ scale: 1.1 }}
  >
    {showPassword ? <RiEyeFill /> : <RiEyeCloseFill />}
  </motion.button>
</motion.div>

 <motion.div className="text-center mt-3" whileHover={{ scale: 1.1 }}>
            {loginFailed && (<Alert variant="danger" style={{marginTop:'5px'}} >Invalid email or password</Alert>)}
        </motion.div>


            <motion.button
              style={styles.button}
             // variant="primary"
              type="submit"
              className= "mt-4 border-0 text-center"
              onClick={handleLogin}
              whileHover={{ scale: 1.1 }}
            >
              LOGIN
            </motion.button>
          </Form>
          <div className="text-center mt-3">
            <motion.button onClick={handleClick} className="small border-0 text-center" style={styles.forgotButton} whileHover={{ scale: 1.1 }}>
              Forgot password?
            </motion.button>
          </div>
        </Card.Body>
      </Card>    
    </motion.div>
  );
}
