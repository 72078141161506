import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from './logo.svg';
import './TextContainer.css'; // Import the CSS file

const styles = {
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "transparent",
  },
  textContainer: {
    backgroundColor: '#f8f8f8',
    color: '#001E1E',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '90vh',
    overflowY: 'auto',
    margin: '5vh 0',
    fontSize: '1.2rem',
    fontFamily: 'Roboto, serif',
    fontWeight: 400,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
   // boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.15)', // Add a subtle shadow

  },
 
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '20px',
  },

  startssButton: {
    backgroundColor: '#008080',
    color: '#F8F8F8',
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto Mono, serif',
    fontWeight: 500,
    width: 'fit-content',
  },
  startButton: {
    // ... other styles
    backgroundColor: '#008080',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
  },
  goBackButton: {
    // ... other styles
     // ... other styles
    backgroundColor: '#FD0B0B',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto, serif',
    fontWeight: 500,
    minWidth: '150px',
    width: '200px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow

  },
  logoStyle: {
    width: '100px',
    height: '100px',
    marginBottom: '10px',
  },
  numberStyle: {
    color: '#e30909',
    fontWeight: 500,
  },
};

const TextContainer = ({continueApp}) => {

  const handleClick = () => {
      continueApp();
    };
  const handleGoBack = () => {
      window.location.reload();
    };
  return (
    <div style={styles.formContainer}>
      <motion.div style={styles.textContainer} className="text-container"  
          initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.3 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }}
       > <motion.div initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ flexShrink: 0 }}>
          <Logo style={styles.logoStyle} />
        </motion.div>
          <motion.h2 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{color:"#FD0B0B", fontFamily: 'Roboto Mono, serif',fontWeight: 700,}}>Get Ready!</motion.h2>
          <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
            <span style={styles.numberStyle}>1.</span>  Get cozy! Find a well-lit spot to use the application, and make sure your face is well-lit with no shadows or glare on the screen.
          </motion.p>
          <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
            <span style={styles.numberStyle}>2.</span>  Get in position! Sit about 20-27 inches (50-70 cm) away from the screen, and make sure you're facing it straight on with your eyes looking forward.
          </motion.p>
          <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
            <span style={styles.numberStyle}>3.</span> Time to calibrate! Before you start using the application, we just need to get to know your gaze patterns. Don't worry, it's quick and easy! Just follow the on-screen instructions to complete the calibration process.
          </motion.p>
          <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
            <span style={styles.numberStyle}>4.</span> Relax and enjoy! Make sure you're comfortable and relaxed while using the application. No need to stress, just sit back and enjoy the experience.
          </motion.p>

          <div style={styles.buttonsContainer}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.goBackButton}
            onClick={handleGoBack}
          >
            Some other time
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.startButton}
            onClick={handleClick}
          >
            I'm Ready
          </motion.div>
          </div>
         
        </motion.div>
      </div>
  );
};

export default TextContainer;