import React, { useState, useEffect, useReducer } from "react";
import NavBar from "./NavigationBar/NavBar";

import appReducer from "./reducers";
import { StateContext } from "./contexts";

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from './NavigationBar/components/Home';
import Info from './NavigationBar/components/Info';
import MyApp from './NavigationBar/components/MyApp';
import MyData from './NavigationBar/components/MyData';
import Welcome from './NavigationBar/components/Welcome';

import Content from './Content';
import LoadingSpinner from './LoadingSpinner';



// NotFound component to handle invalid routes
const NotFound = () => {
  const storedRoute = localStorage.getItem('currentRoute') || '/home';
  return <Navigate to={storedRoute} replace />;
};


export default function App() {

  const [state, dispatch] = useReducer(appReducer, {
    user: "",
    appState:false,
  });

   const { user,appState } = state;
  // console.log("burdayim");
   //console.log(appState);

   // Update the state from localStorage on component initialization
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    // Get the stored route from localStorage
   // const storedRoute = localStorage.getItem('currentRoute') || '/home';
    if (userToken) {
      dispatch({ type: "LOGIN", token: userToken });
    }
   // dispatch({ type: 'SET_APP_TRUE' });
    dispatch({ type: 'SET_APP_FALSE' });
    setIsAppInitialized(true); // Set app initialization state to true
  }, []);

 // Update storedRoute to '/home' when the user quits unexpectedly
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('currentRoute', '/home');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [fontSize, setFontSize] = useState(Math.min(window.innerWidth / 55, 12));

  useEffect(() => {
    const handleResize = () => {
      setFontSize(Math.min(window.innerWidth / 55, 12)); // Set the maximum font size to 12px
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="App" style={{height:'100vh',backgroundColor:"#b2d8d8"}}>
      <StateContext.Provider  value={{ state, dispatch }}>
        <React.Suspense fallback={<LoadingSpinner />}>

            <Router>
            <NavBar />
           
            <Routes>
            <Route path="/" element={<Content />}>
              <Route index element={<Welcome/>} />
               {isAppInitialized && user ? ( // Check if the app is initialized
                <>
                  <Route path="/home" element={<Home />} />
                  <Route path="/myData" element={<MyData/>} />
                  <Route path="/myApp" element={<MyApp/>} />
                  <Route path="/info" element={<Info/>} />
                  <Route path="*" element={<NotFound />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/" replace />} />
              )}
            </Route>
          </Routes>
       
          </Router>
        </React.Suspense>   
      </StateContext.Provider>
       
      <p
        style={{
          position: 'fixed',
          bottom: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: `${fontSize}px`, // Set the calculated font size
          textAlign: 'center',
          zIndex: 12,
          marginBottom: '2px',
          fontFamily: 'Roboto Mono, serif',
          fontWeight: '300',
          color: '#001E1E',
        }}
      >
        © 2023 Baris Dingil. All Rights Reserved.
      </p>
   
    </div>
  );
}