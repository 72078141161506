import React, { useState} from 'react';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { RiEyeFill, RiEyeCloseFill } from 'react-icons/ri';
//                 red      orange    yellow    green     blue      violet
const colors = ['#FD0B0B','#f96c22','#fdeb31','#7bc043','#0392cf','#a660cf']

const getShapeStyles = (diameter,color) => {
  const styles = {
    circle: {
      shapeSt: {
        width: diameter,
        height: diameter,
        borderRadius: '50%',
        backgroundColor:color,
      },
      shapeEye: {
        position: "absolute",
        left: diameter / 4,
        top: diameter / 4,
        color: '#001E1E',
      },
    },
  };
  return styles["circle"];
};

const Callibrate = React.memo(({ point, handleButton,index }) => {

    const [clicks, setClicks] = useState(1);
    const { x, y, diameter } = point;
 
    const [pointColor, setPointColor] = useState("#FD0B0B");

    const shapeStyles = {
      width: diameter,
      height: diameter,
      position: 'absolute',
      top: y,
      left: x,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: diameter/2,
      color: "#001E1E",
      cursor: "pointer"
    };

  const renderEyeAnimation = (shapeEye) => {
  if (clicks<7) {
    return(
      <motion.div>
        <motion.div style={{ ...shapeEye }}>
          <RiEyeFill style={{ opacity: 1.0 }} />
        </motion.div>
      </motion.div>
      );
  } else {
    return (
      <motion.div>
        <motion.div style={{ ...shapeEye }}>
          <RiEyeCloseFill style={{ opacity: 1.0 }} />
        </motion.div>
      </motion.div>
    );
  }
};

  const handleClick = () => {
  setClicks((prev) => prev + 1);

  switch (clicks) {
    case 1:
      setPointColor(colors[1]);
      break;
    case 2:
      setPointColor(colors[2]);
      break;
    case 3:
      setPointColor(colors[3]);
      break;
    case 4:
      setPointColor(colors[4]);
      break;
    case 5:
      setPointColor(colors[5]);
      break;
    default:
      setPointColor("gray");
      handleButton(point);
  }
};

    const { shapeSt, shapeEye  } = getShapeStyles(diameter,pointColor);

    let shapeElement = (
      <motion.div style={{ ...shapeSt }}
        key={uuidv4()}
        onClick={handleClick}
        >
        {renderEyeAnimation(shapeEye)}
      </motion.div>
      );
  return (
    <motion.div
      key={index}
      style={shapeStyles}
      animate={{ scale: [0.5, 0.53, 0.5] }}
      whileHover={{ scale: 0.55 }}
    >
      {shapeElement}
    </motion.div>
  );
});

export default Callibrate;