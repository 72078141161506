import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from './logo.svg';
import './TextContainer.css'; // Import the CSS file
import { useScore } from './ScoreContext';
import { RiDatabase2Fill} from "react-icons/ri";

const styles = {
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: "transparent",
  },
  textContainer: {
    backgroundColor: '#f8f8f8',
    color: '#001E1E',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '90vh',
    overflowY: 'auto',
    margin: '5vh 0',
    minWidth: "400px",
    fontSize: '15px',
    fontFamily: 'Roboto, serif',
    fontWeight: 400,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
   // boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.15)', // Add a subtle shadow

  },
 
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '20px',
  },

  startssButton: {
    backgroundColor: '#008080',
    color: '#F8F8F8',
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto Mono, serif',
    fontWeight: 500,
    width: 'fit-content',
  },
  startButton: {
    // ... other styles
    backgroundColor: '#008080',
    color: "#f8f8f8",
    border: "none",
    borderRadius: '20px',
    padding: '8px 16px',
    marginTop: '20px',
    cursor: 'pointer',
    fontFamily: 'Roboto Mono, serif',
    fontWeight: 500,
    minWidth: '250px',
    width: '250px', // Set a fixed width for both buttons
  height: '40px', // Set a fixed height for both buttons
  lineHeight: '24px', // Adjust the line-height to vertically center the text
  textAlign: 'center',
  fontSize: '1.2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
 // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
  },
  
  logoStyle: {
    width: '100px',
    height: '100px',
    marginBottom: '10px',
  },
  numberStyle: {
    color: '#e30909',
    fontWeight: 500,
  },
};

const TextScoreContainer = () => {
  const { score } = useScore();
  const handleClick = () => {
      window.location.reload();
    };
  return (
    <div style={styles.formContainer}>
      <motion.div style={styles.textContainer} className="text-container"  
          initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.3 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }}
       > <motion.div initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{ flexShrink: 0 }}>
          <Logo style={styles.logoStyle} />
        </motion.div>
          <motion.h2 initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.1 }} style={{color:"#FD0B0B", fontFamily: 'Roboto Mono, serif',fontWeight: 700,}}>Here is Your Score!</motion.h2>
          
          
         <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.5}}  style={{ fontWeight: 700, fontFamily: 'Roboto Mono, serif',fontSize:"50px" }}>{score}</motion.p>
          
        <motion.p initial={{ scale: 0 }} // Start from a scale of 0 (completely collapsed)
          animate={{ scale: 1 }} // Animate to a scale of 1 (normal size)
          transition={{ duration: 0.2 }} // Set the duration of the animation>
          whileHover={{ scale: 1.03 }} >
          To track your progress and analyze your performance, please visit the &nbsp;
          <RiDatabase2Fill style={{width: '25px', height: '25px', color:"#008080"}} /> &nbsp;icon in the navigation bar.
 
         
        </motion.p>
          <div style={styles.buttonsContainer}>
          
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={styles.startButton}
            onClick={handleClick}
          >
            ATTENTION VISUAL
          </motion.div>
          </div>
         
        </motion.div>
      </div>
  );
};

export default TextScoreContainer;