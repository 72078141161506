import React ,{useContext,useEffect}from 'react';
import "../LoggedNavigation/LoggedCss.css";
//import tttt from './tttt.jpeg';
//import tt from './tt.jpeg';
import { ReactComponent as Logo } from '../logo.svg';
import { FaRegEnvelope, FaInstagramSquare, FaTwitter, FaTiktok } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { StateContext } from '../../contexts';
import { useNavigate } from 'react-router-dom';

/*
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
          <img src={tttt} alt="Header" style={{ borderRadius: "50%", width: "150px", height: "150px", objectFit: "cover" }} />
        </div>
<div style={{ display: "flex", justifyContent: "center", marginBottom: '0px' }}>
          <Logo style={{width:"150px"}}/>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
          <img src={tt} alt="Header" style={{ borderRadius: "50%", width: "150px", height: "150px", objectFit: "cover" }} />
        </div>

        
        <div style={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: "center" }}>Feature Highlights:</div>
*/
import t from './t.jpeg';
import tt from './tt.jpeg';
import ttt from './ttt.jpeg';
import tttt from './tttt.jpeg';

const Welcome = () => {
  const navigate = useNavigate();
  const { state } = useContext(StateContext);
  const { user } = state;

  // If the user is logged in, navigate to /home
  useEffect(() => {
    // If the user is logged in, navigate to /home
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  return (
    <div className="formContentDiv" style={{ backgroundColor:'#165858',color:"#f8f8f8" }}>
       <motion.div animate={{ scale: [0.2, 1.3, 1] }}
                  transition={{ ease: "easeIn", duration: 0.7 }} style={{ 
    height:"100%",
    width:"90%",
    padding:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }}>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#b2d8d8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto',fontWeight:700, fontSize: '65px', marginBottom: '5px' }}>
         Attention Visual
        </motion.div>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Harness AI and Machine Learning for Enhanced Gaze Tracking and Research
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
            Welcome to Attention Visual, a state-of-the-art web application designed to revolutionize gaze tracking for research purposes. Utilizing advanced AI and machine learning techniques, our platform collects valuable data about users' visual attention and cognitive patterns. This enables users to improve their focus, boost their cognitive skills, and ultimately enhance their overall experience and performance in various tasks.        
        </motion.div>
        
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  
  <div style={{ display: "grid", justifyContent: "center", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gridGap: "20px" , justifyItems: "center"}}>
    {[
  {
    title: "Easy-to-Use Interface",
    text: "Attention Visual offers an intuitive and user-friendly interface that makes it simple to set up and conduct gaze tracking sessions.",
    img: t,
  },
  {
    title: "Seamless Integration",
    text: "Our web application ensures accurate and reliable results by integrating with the latest gaze tracking technologies.",
    img: tt,
  },
  {
    title: "Customizable Tests",
    text: "Design and implement your gaze tracking tests to meet your unique research objectives, whether it's understanding user behavior, optimizing content layout, or evaluating design elements through AI-driven analysis.",
    img: ttt,
  },
  {
    title: "Data Analysis",
    text: "Harness the power of real-time data visualization and in-depth analysis to uncover actionable insights and drive your research forward.",
    img: tttt,
  },
].map((feature, index) => (
      <motion.div key={index} whileHover={{ scale: 1.15 }} style={{ width: "200px", padding: "10px", display: "grid", gridTemplateRows: "100px auto auto", textAlign: "center", fontFamily: 'Karla', fontSize: '14px' ,marginBottom:"20px"}}>
        <img src={feature.img} alt={feature.title} style={{ borderRadius: "50%", width: "100px", height: "100px", objectFit: "cover", marginBottom: "10px", alignSelf: "end", justifySelf: "center" }} />
        <div style={{ fontWeight: '700',fontSize:"16px", marginBottom: '5px', alignSelf: "start" }}>{feature.title}<br></br>
          <span style={{fontWeight: '400',color:"#b2d8d8"}}>{feature.text}</span></div>
      </motion.div>
))}
          </div>
        </div>

          <div style={{
  borderTop: '1px solid #b2d8d8',
  padding: '20px',
  textAlign: 'center',
  fontFamily: 'Roboto Mono',
  fontWeight:400,
}}>
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}></div>
<motion.div whileHover={{ scale: 1.02 }} style={{ whiteSpace: 'nowrap', margin: '18px',marginBottom:"5px" }}>
      Contact Us:
    </motion.div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontFamily: 'Roboto', flexWrap: 'wrap' }}>
    
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaRegEnvelope style={{ color: "#D66532", fontSize: "25px" }} /> <a style={{color:"#D66532"}} href="mailto:info@attentionvisual.com">&nbsp;info@attentionvisual.com</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaInstagramSquare style={{ color: "#C13584", fontSize: "25px" }} /> <a style={{color:"#C13584"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTiktok style={{color:"#000000", fontSize:"25px"}}/> <a style={{color:"#000000"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTwitter style={{ color: "#1DA1F2", fontSize: "25px" }} /> <a style={{color:"#1DA1F2"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>

    <motion.div whileHover={{ scale: 1.05 }} style={{ whiteSpace: 'nowrap', margin: '18px' }}>
                  <div

                      

                       style={{
                              fontSize: '18px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '500',
                              color:"f8f8f8"
                             }}

                   
                    >
      <Logo style={{ width: '45px', height: '45px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '45px', height: '45px', transform: 'scaleX(-1)' }}
      />
    </div>
  </motion.div>
  </div>
                  
</div>
       
      </motion.div>
    </div>
  );
};

export default Welcome;