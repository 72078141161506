import React from 'react';
import "../LoggedNavigation/LoggedCss.css";
import { ReactComponent as Logo } from '../logo.svg';
import { FaRegEnvelope, FaInstagramSquare, FaTwitter, FaTiktok } from 'react-icons/fa';
import { motion } from 'framer-motion';
import t from './t.jpeg';
import tt from './tt.jpeg';
import ttt from './ttt.jpeg';
import tttt from './tttt.jpeg';

const Info = () => {
  return (
    <div className="formContentDiv" style={{ backgroundColor: "#237878" ,color:"#f8f8f8"  }}>
    <motion.div animate={{ scale: [1.03, 1] }}
                  transition={{ ease: "easeIn", duration: 0.3 }} style={{ 
    height:"100%",
    width:"90%",
    padding:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }}>
        
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#b2d8d8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Roboto',fontWeight:700, fontSize: '60px', marginBottom: '5px' }}>
         Attention Visual Info
        </motion.div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <img src={ttt} alt="Gaze tracking illustration" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }} />
</div>
        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          About the Application:
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
           Attention Visual is a cutting-edge gaze tracking platform designed to revolutionize the way we understand and analyze visual attention patterns. Our mission is to provide an accessible and user-friendly tool that empowers users to unlock valuable insights into their cognitive behavior, ultimately helping them optimize their experiences, communication, and learning processes.<br></br>
<br></br>
The inspiration behind Attention Visual stems from a desire to create a platform that democratizes gaze tracking technology, making it available to a wider audience. By harnessing the power of advanced artificial intelligence and machine learning techniques, we've built a platform that seamlessly integrates with the latest gaze tracking technologies, offering reliable and accurate results.<br></br>
<br></br>
As we continue to develop Attention Visual, we envision expanding its applications beyond user experience and communication optimization. Our goal is to explore its potential in areas such as cognitive disorder detection, cognitive therapy, education, and other mental health-related fields, ultimately creating a versatile tool that can cater to a wide range of needs and research objectives.<br></br>
<br></br>
By using Attention Visual, users can participate in a variety of gaze tracking tests that allow them to better understand their visual attention patterns. Our platform offers real-time data visualization and in-depth analysis, enabling users to uncover actionable insights that can drive research forward, enhance their understanding of human cognition, and improve teaching and learning experiences.<br></br>
<br></br>
We believe that Attention Visual has the potential to transform the way we study visual attention and cognitive behavior, and we're excited to continue our journey in developing the platform to its fullest potential. Your participation and feedback are invaluable in helping us shape the future of Attention Visual and contribute to a greater understanding of the human mind.<br></br>
        </motion.div>


        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          How it Works:
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
Get cozy! Find a well-lit spot to use the application, and make sure your face is well-lit with no shadows or glare on the screen.
<br></br><br></br>
Get in position! Sit about 20-27 inches (50-70 cm) away from the screen, and make sure you're facing it straight on with your eyes looking forward.
<br></br><br></br>
Time to calibrate! Before you start using the application, we just need to get to know your gaze patterns. Don't worry, it's quick and easy! Just follow the on-screen instructions to complete the calibration process.
<br></br><br></br>
Relax and enjoy! Make sure you're comfortable and relaxed while using the application. No need to stress, just sit back and enjoy the experience.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Calibration Magic:</span>
<br></br><br></br>
Get ready to calibrate the gaze tracker algorithm! You will see a screen with a bunch of points scattered around.
<br></br><br></br>
Simply click on each point with your mouse or tap with your finger. Watch them magically turn gray as you click on them!
<br></br><br></br>
Continue clicking until ALL the points turn gray. This means the calibration is complete!
<br></br><br></br>
Say hello to Gaze Turtle,&nbsp;<Logo style={{width: '25px', height: '25px'}} /> the fancy icon that shows where you're looking on the screen. After calibration, Gaze Turtle will appear and follow your gaze. Keep in mind, Gaze Turtle can't move as fast as your eyes, but it's trying its best to keep up!
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Visual Attention Training Session:</span>
<br></br><br></br>
Start the Session: Press the "Start Session" button to begin your 5-minute visual attention training session. Get ready, the fun is about to begin!
<br></br><br></br>
AI-Powered Challenge: During the session, you'll see a bunch of points on the screen. As you progress, our advanced machine learning algorithm will adapt the challenge to your performance. You'll face more shapes, colors, and increased density of points, all tailored to optimize your training experience. Stay focused and watch out for the animated point!
<br></br><br></br>
Master the Illusions: The animated point will perform one of the following animations, known as illusions: resize, recolor, reshape, rotate, or blink. Your goal is to click on the animated point as quickly and accurately as possible.
<br></br><br></br>
Track Your Progress: For each correct click, you'll earn 3 points. However, if you click on the wrong point, you'll lose 1 point. Keep practicing every day to improve your visual attention and increase your score!
<br></br><br></br>
Stay Relaxed: Remember, the key to success is staying relaxed and focused. Enjoy the session, and don't forget to breathe!
<br></br><br></br>
Ready to challenge your visual attention with our cutting-edge AI-powered training? Press "Start Session" and let the magic begin!
        </motion.div>


        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Features and Benefits:
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
Attention Visual boasts a diverse range of features designed to provide an unparalleled experience in gaze tracking and visual attention analysis. These features, combined with the platform's user-friendly interface and state-of-the-art technology, bring a myriad of benefits to users, researchers, and educators alike.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Intuitive and User-Friendly Interface:</span><br></br>
Attention Visual's easy-to-use interface simplifies the process of setting up and conducting gaze tracking sessions, allowing users to focus on their research objectives and cognitive exploration.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Seamless Integration with Gaze Tracking Technologies:</span><br></br>
Our platform integrates seamlessly with the latest gaze tracking technologies, ensuring accurate and reliable results that users can trust for their research and personal development.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Customizable Gaze Tracking Tests:</span><br></br>
Design and implement gaze tracking tests tailored to your unique objectives, whether you're studying user behavior, optimizing content layout, evaluating design elements, or investigating cognitive disorders.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Real-Time Data Visualization and Analysis:</span><br></br>
Harness the power of real-time data visualization and in-depth analysis to uncover actionable insights and drive your research forward. Attention Visual's data analysis capabilities facilitate a better understanding of visual attention patterns and cognitive behavior.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Versatile Applications:</span><br></br>
Attention Visual's potential applications span various domains, including user experience optimization, communication enhancement, cognitive disorder detection, cognitive therapy, and education. This versatility makes our platform a valuable tool for a wide range of users and researchers.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Continuous Development and Improvement:</span><br></br>
As we continue to develop and refine Attention Visual, we remain committed to expanding its features and applications to better serve our users. Your participation and feedback are crucial in helping us shape the future of Attention Visual and contribute to a greater understanding of the human mind.
        </motion.div>


  <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Frequently Asked Questions (FAQs):
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
<span style={{fontWeight:500, fontSize:"27px"}}>What devices are compatible with Attention Visual?</span><br></br>
Attention Visual is designed to work on devices with a screen width of at least 900 pixels. For the best experience, we recommend using a laptop, desktop computer, or tablet with a large screen.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>How do I set up and conduct a gaze tracking test?</span><br></br>
Setting up and conducting a gaze tracking test with Attention Visual is simple. After logging in, navigate to the MyApp section and click on the "Launch the Application" button. Follow the on-screen instructions to calibrate your device and begin the test.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Can I customize the gaze tracking tests?</span><br></br>
Yes, Attention Visual allows you to design and implement gaze tracking tests tailored to your unique objectives. To customize a gaze tracking test, please contact our team at <span style={{fontWeight:400,wordBreak: 'break-all'}}>info@attentionvisual.com</span>. They will help you create a test that meets your specific needs, whether it's studying user behavior, optimizing content layout, evaluating design elements, or investigating cognitive disorders more effectively.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Is my data secure on Attention Visual?</span><br></br>
We take data security very seriously at Attention Visual. All user data is securely stored, and we maintain strict privacy policies to ensure that your information remains confidential and protected.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>How can Attention Visual help with cognitive disorder detection and therapy?</span><br></br>
Attention Visual's customizable gaze tracking tests and real-time data visualization can be used to study cognitive behavior, identify patterns associated with cognitive disorders, and develop targeted interventions or therapies.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Can I use Attention Visual for educational purposes?</span><br></br>
Absolutely! Attention Visual's gaze tracking capabilities can be employed to create engaging teaching and learning tools for students and educators. By analyzing visual attention patterns, teachers can better understand how students interact with educational content and adapt their teaching methods accordingly.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>How can I stay informed about new features and improvements to Attention Visual?</span><br></br>
You can stay up-to-date with the latest developments and improvements by regularly visiting our Info section. Additionally, consider subscribing to our newsletter or following us on social media to receive updates directly.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>How can I provide feedback or report an issue with Attention Visual?</span><br></br>
We value your feedback and encourage you to share any comments or concerns with us. You can reach out to our support team via email at <span style={{fontWeight:400,wordBreak: 'break-all'}}>support@attentionvisual.com</span>, or contact us through our social media channels.
        </motion.div>


        <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Updates and Roadmap:
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
<span style={{fontWeight:500, fontSize:"27px"}}>Version 1.0 - Initial Release</span>
<br></br><br></br>
We are excited to announce the launch of AttentionVisual, a cutting-edge app that offers a user-friendly platform for analyzing data. This first version of the app is designed to provide an accessible starting point for data analysis and visualization, with more advanced features and detailed myData analyses planned for future releases.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Upcoming Features</span>
<br></br><br></br>
In our next release, we plan to introduce more detailed myData analyses, further enhancing the app's capabilities and usefulness. Our team is continuously working on improving and expanding the functionalities of AttentionVisual to cater to a broader range of use cases.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Long-term Vision</span>
<br></br><br></br>
Our long-term vision for AttentionVisual is to provide a versatile and powerful tool for researchers, educators, students, and everyone interested in leveraging real-time data for various purposes. By making the app accessible and user-friendly, we hope to create an environment where many diseases can be detected, and treatments can be offered. Additionally, we aim to improve users' visual cognition through the utilization of our app.
<br></br><br></br>
We believe that by continuously refining AttentionVisual and incorporating user feedback, we can make it an indispensable resource for individuals and organizations across various domains.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>User Feedback</span>
<br></br><br></br>
Your feedback is invaluable to us, as it helps us understand your needs and guides the future development of AttentionVisual. If you have any suggestions, concerns, or ideas for new features, please reach out to our support team at <span style={{fontWeight:400,wordBreak: 'break-all'}}>support@attentionvisual.com</span>. We are eager to hear from you and will do our best to address your feedback in future updates.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Get Involved</span>
<br></br><br></br>
We are always looking for ways to improve and grow our app. If you have ideas or expertise that you think could benefit AttentionVisual, please don't hesitate to reach out to us. We are open to collaboration and appreciate the involvement of our user community in making AttentionVisual the best it can be.
<br></br><br></br>
Stay tuned for more updates and exciting features as we continue to develop AttentionVisual. Thank you for being a part of our journey!
        </motion.div>


 <motion.div whileHover={{ scale: 1.01 }} style={{ color:"#f8f8f8", backgroundColor: 'transparent', textAlign: "center", fontFamily: 'Comfortaa',fontWeight:600, fontSize: '40px', marginBottom: '20px' }}>
          Support and Contact:
        </motion.div>
        
       
        <motion.div whileHover={{ scale: 1.01 }} style={{color:"#b2d8d8" ,fontFamily: 'Roboto, serif', fontWeight: 300, fontSize: '25px', textAlign: "center", marginBottom: '50px' }}>
        We understand the importance of providing timely and effective support to our users. Our dedicated support team is here to assist you with any questions, issues, or concerns you may have regarding AttentionVisual.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Support Channels</span>
<br></br><br></br>
If you need assistance or have any inquiries, please reach out to us through the following channels:
<br></br><br></br>
<span style={{fontWeight:400}}>Email:</span> For general inquiries, feedback, or technical support, please send an email to <span style={{fontWeight:400,wordBreak: 'break-all'}}>support@attentionvisual.com</span>. Our support team will respond to your query as quickly as possible.
<br></br><br></br>
<span style={{fontWeight:400}}>Knowledge Base:</span> We have a comprehensive Knowledge Base available on our website that covers various topics, including how to use the app, troubleshooting common issues, and understanding different features. Be sure to check it out for a wealth of information and guidance.
<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Social Media</span>
<br></br><br></br>
Follow us on our social media channels to stay updated on the latest news, updates, and announcements related to AttentionVisual:
<br></br><br></br>

<span>Instagram:&nbsp; <a style={{color:"#b2d8d8"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span><br></br>
<span>TikTok:&nbsp; <a style={{color:"#b2d8d8"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span><br></br>
<span>Twitter:&nbsp; <a style={{color:"#b2d8d8"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span><br></br>

<br></br><br></br>
<span style={{fontWeight:500, fontSize:"27px"}}>Feedback</span>
<br></br><br></br>
Your feedback is crucial to the continued improvement and development of AttentionVisual. If you have any suggestions, concerns, or ideas for new features, please don't hesitate to reach out to us at <span style={{fontWeight:400,wordBreak: 'break-all'}}>support@attentionvisual.com</span>. We greatly appreciate your input and will do our best to address your feedback in future updates.
<br></br><br></br>
        </motion.div>
        
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: '20px' }}>
  

          
        </div>

      <div style={{
  borderTop: '1px solid #b2d8d8',
  padding: '20px',
  textAlign: 'center',
  fontFamily: 'Roboto Mono',
  fontWeight:400,
}}>
 <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}></div>
<motion.div whileHover={{ scale: 1.02 }} style={{ whiteSpace: 'nowrap', margin: '18px',marginBottom:"5px" }}>
      Contact Us:
    </motion.div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', fontFamily: 'Roboto', flexWrap: 'wrap' }}>
    
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaRegEnvelope style={{ color: "#D66532", fontSize: "25px" }} /> <a style={{color:"#D66532"}} href="mailto:info@attentionvisual.com">&nbsp;info@attentionvisual.com</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaInstagramSquare style={{ color: "#C13584", fontSize: "25px" }} /> <a style={{color:"#C13584"}} href="https://www.instagram.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTiktok style={{color:"#000000", fontSize:"25px"}}/> <a style={{color:"#000000"}} href="https://www.tiktok.com/@attentionvisual" target="_blank" rel="noopener noreferrer">@attentionvisual</a></span>
    </motion.div>
    <motion.div whileHover={{ scale: 1.08 }} style={{ whiteSpace: 'nowrap', margin: '10px' }}>
      <span><FaTwitter style={{ color: "#1DA1F2", fontSize: "25px" }} /> <a style={{color:"#1DA1F2"}} href="https://www.twitter.com/attentionvisual" target="_blank" rel="noreferrer">@attentionvisual</a></span>
    </motion.div>

    <motion.div whileHover={{ scale: 1.05 }} style={{ whiteSpace: 'nowrap', margin: '18px' }}>
                  <div

                      

                       style={{
                              fontSize: '18px',
                              fontFamily: 'Roboto Mono, serif',
                              fontWeight: '500',
                              color:"f8f8f8"
                             }}

                   
                    >
      <Logo style={{ width: '45px', height: '45px' }} />
      &nbsp;ATTENTION VISUAL&nbsp;
      <Logo
        style={{ width: '45px', height: '45px', transform: 'scaleX(-1)' }}
      />
    </div>
  </motion.div>
  </div>
                  
</div>
       
      </motion.div>
    </div>
  );
};


export default Info;